import React from 'react'

import LogIn from './LogIn'
import Register from './Register'
import ForgotPassword from './ForgotPassword'
import ChangePassword from './ChangePassword'

import './Auth.scss'
import FinishRegister from './FinishRegister'
import AccountVerify from './AccountVerify/AccountVerify'

const Auth = ({ dark, formType }) => {
  const renderForm = (type) => {
    switch (type) {
      case 'register':
        return <Register />
      case 'logIn':
        return <LogIn />
      case 'forgot':
        return <ForgotPassword />
      case 'finishRegister':
        return <FinishRegister />
      case 'changePassword':
        return <ChangePassword />
      case 'accountVerify':
        return <AccountVerify />
      default:
        return
    }
  }

  const formClassList = `auth ${dark ? 'auth--dark' : ''}`
  return (
    <div className={formClassList} data-testid="authModal">
      {renderForm(formType)}
    </div>
  )
}

export default Auth
