import { fork } from 'redux-saga/effects'

import { watchUser } from './userSaga'
import { watchAuth } from './authSaga'
import { watchProjects } from './projectsSaga'

export default function* rootSaga() {
  yield fork(watchUser)
  yield fork(watchAuth)
  yield fork(watchProjects)
}
