import React, { useEffect } from 'react'
import { Accordion, AccordionItem, Button } from 'carbon-components-react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Application from './Application'
import { actions } from '../../../constants'
import { headerClearAction } from '../../../store/header/actions'
import TeamMember from '../TeamMember'

const NotAssigned = ({ project, isApplication, toggleApplication }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const header = useSelector(({ header }) => header)

  useEffect(() => {
    if (header.action === actions.HEADER_GO_BACK) {
      isApplication ? toggleApplication() : history.goBack()
      dispatch(headerClearAction())
    }
  }, [header, dispatch, history, isApplication, toggleApplication])

  const application = (
    <Application positions={project.positions} close={toggleApplication} />
  )

  const positions = project.positions.map((pos) => (
    <AccordionItem key={pos.uuid} title={pos.name}>
      <p className="c-text">{pos.description}</p>
    </AccordionItem>
  ))

  const projectInfo = (
    <div className="container container--flex-md">
      <div className="project-detail__brief">
        <p className="c-label">Project Breif</p>
        <p className="c-text">{project.description}</p>
        <Button className="bx--btn--hide-md" onClick={toggleApplication}>
          I&apos;m Interested
        </Button>
        <TeamMember
          className="member--hide member--show-md"
          member={project.projectManager}
        />
      </div>
      <div className="project-detail__positions">
        <p className="c-label">Available Positions</p>
        <Accordion align="end">{positions}</Accordion>
        <TeamMember
          className="member--hide-md"
          member={project.projectManager}
        />
      </div>
    </div>
  )

  return isApplication ? application : projectInfo
}

export default NotAssigned
