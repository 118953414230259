import { useLayoutEffect, useState, useCallback } from 'react'

function useLockBodyScroll() {
  const [originalStyle, setOriginalStyle] = useState(null)

  const unlock = useCallback(
    () => (document.body.style.overflow = originalStyle),
    [originalStyle]
  )

  useLayoutEffect(() => {
    const style = window.getComputedStyle(document.body).overflow
    setOriginalStyle(style)
    document.body.style.overflow = 'hidden'
    return () => unlock()
  }, [unlock])

  return [unlock]
}

export default useLockBodyScroll
