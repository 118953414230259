import { addMethod, mixed } from 'yup'

export const asset = (string) => {
  // eslint-disable-next-line
  return /^(https?):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
    string
  )
}

addMethod(mixed, 'asset', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: 'Please enter valid url (it should contain http:// or https://)',
    test: (value) => {
      if (value) {
        return asset(value)
      } else {
        return true
      }
    }
  })
})
