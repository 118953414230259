import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'carbon-components-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setHideModal } from '../../store/modal/actions'
import {
  projectsFetchAll,
  projectsFetchMy,
  projectsPatchVisited
} from '../../store/projects/actions'
import ProjectsSkeleton from './ProjectsSkeleton'

import './Projects.scss'

const Projects = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [projectsAll, projectsMy] = useSelector(({ projects }) => [
    projects.all,
    projects.my
  ])
  const [myProjectsTab, setMyProjectsTab] = useState(false)

  useEffect(() => {
    document.title = 'Lightbox | Projects'
    dispatch(setHideModal())
    dispatch(projectsFetchAll())
    dispatch(projectsFetchMy())
    // eslint-disable-next-line
  }, [])

  const handleTabChange = (e) => {
    e.persist()
    if (e.target.id === 'myProjects') {
      dispatch(projectsFetchMy())
      setMyProjectsTab(true)
    } else {
      dispatch(projectsFetchAll())
      setMyProjectsTab(false)
    }
  }

  let allProjectsList
  let myProjectsList

  const customizeName = (name) => {
    let nameByWords = name.split(' ')
    let newName
    if (nameByWords.length > 1) {
      const lastWord = nameByWords[nameByWords.length - 1]
      nameByWords.pop()
      newName = (
        <>
          <span className="part-first">{nameByWords.join(' ')}</span>{' '}
          <span className="part-second">{lastWord}</span>
        </>
      )
    } else {
      newName = <span className="part-second">{nameByWords}</span>
    }
    return newName
  }

  const createProjectsList = (items, handler) => {
    return items.map((project) => (
      <div key={project.uuid} className="projects__item">
        <div
          className="c-project"
          style={{ backgroundImage: `url(${project.titlePhoto})` }}
          onClick={() => handler(project)}
        >
          {project.status === 'new' ? (
            <div className="c-project__new">New</div>
          ) : (
            ''
          )}
          <div className="c-project__name">{customizeName(project.name)}</div>
        </div>
      </div>
    ))
  }

  const handleClick = (project) => {
    if (project.status === 'new') {
      dispatch(projectsPatchVisited(project.uuid))
    }
    history.push(
      `${history.location.pathname}/${project.uuid}?tab=${
        myProjectsTab ? 'my-projects' : 'open-projects'
      }`
    )
  }

  if (projectsAll) {
    allProjectsList = createProjectsList(projectsAll, handleClick)
  }

  if (projectsMy) {
    if (projectsMy.length) {
      myProjectsList = createProjectsList(projectsMy, handleClick)
    } else {
      myProjectsList = (
        <p className="c-text">
          You are not currently a team member of any projects.
          <br />
          Please go to the All Projects tab and apply for one you’re interested
          in.
        </p>
      )
    }
  }

  const projectsSkeleton = (
    <>
      <div className="projects__item">
        <ProjectsSkeleton />
      </div>
      <div className="projects__item">
        <ProjectsSkeleton />
      </div>
      <div className="projects__item">
        <ProjectsSkeleton />
      </div>
      <div className="projects__item">
        <ProjectsSkeleton />
      </div>
    </>
  )

  return (
    <>
      <div className="projects">
        <div className="container">
          <Tabs className="projects__tabs">
            <Tab
              id="allProjects"
              label="All Projects"
              onClick={handleTabChange}
            >
              <div className="projects__list">
                {projectsAll ? allProjectsList : projectsSkeleton}
              </div>
            </Tab>
            <Tab id="myProjects" label="My Projects" onClick={handleTabChange}>
              <div className="projects__list">
                {projectsMy ? myProjectsList : projectsSkeleton}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default Projects
