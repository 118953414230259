import { takeEvery, put, call, all, select } from 'redux-saga/effects'

import UserService from '../services/user-service'
import FirebaseService from '../services/firebase-service'
import {
  userFetchSuccess,
  userFetchFailure,
  userCreateSuccess,
  userCreateFailure,
  userUpdateSuccess,
  userUpdateFailure,
  userStartLoading,
  userStopLoading
} from '../store/user/actions'
import { actions } from '../constants'

const userService = new UserService()
const firebaseService = new FirebaseService()

function* fetchUser(action) {
  const getIsRegister = (state) => state.auth.isRegister
  const isRegister = yield select(getIsRegister)
  if (!isRegister) {
    try {
      yield put(userStartLoading())
      const token =
        action.type === '@@reactReduxFirebase/LOGIN'
          ? yield call(firebaseService.getUserToken)
          : action.payload
      const response = yield call(userService.getUser, token)
      yield put(userFetchSuccess(response))
      yield put(userStopLoading())
    } catch (error) {
      yield put(userFetchFailure(error))
      yield put(userStopLoading())
    }
  }
}

function* createUser(action) {
  try {
    yield put(userStartLoading())
    const response = yield call(userService.postUser, action.payload)
    yield put(userCreateSuccess(response))
    yield put(userStopLoading())
  } catch (error) {
    yield put(userCreateFailure(error))
    yield put(userStopLoading())
  }
}

function* updateUser(action) {
  try {
    yield put(userStartLoading())

    const token = yield call(firebaseService.getUserToken)

    const getUser = (state) => state.user.user
    const user = yield select(getUser)

    const newUser = { ...user, ...action.payload }

    const response = yield call(userService.putUser, token, newUser)
    yield put(userUpdateSuccess(response))
    yield put(userStopLoading())
  } catch (error) {
    yield put(userUpdateFailure(error))
    yield put(userStopLoading())
  }
}

export function* watchUser() {
  yield all([
    takeEvery('@@reactReduxFirebase/LOGIN', fetchUser),
    takeEvery(actions.AUTH_REGISTER_SUCCESS, createUser),
    takeEvery(actions.USER_UPDATE, updateUser)
  ])
}
