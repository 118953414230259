import { actions } from '../../constants'

const projectsFetchAll = () => ({ type: actions.PROJECTS_FETCH_ALL })
const projectsFetchAllSuccess = (data) => ({
  type: actions.PROJECTS_FETCH_ALL_SUCCESS,
  payload: data
})
const projectsFetchAllFailure = (err) => ({
  type: actions.PROJECTS_FETCH_ALL_FAILURE,
  payload: err
})

const projectsFetchMy = () => ({ type: actions.PROJECTS_FETCH_MY })
const projectsFetchMySuccess = (data) => ({
  type: actions.PROJECTS_FETCH_MY_SUCCESS,
  payload: data
})
const projectsFetchMyFailure = (err) => ({
  type: actions.PROJECTS_FETCH_MY_FAILURE,
  payload: err
})

const projectsFetchDetail = (uuid) => ({
  type: actions.PROJECTS_FETCH_DETAIL,
  payload: uuid
})
const projectsFetchDetailSuccess = (data) => ({
  type: actions.PROJECTS_FETCH_DETAIL_SUCCESS,
  payload: data
})
const projectsFetchDetailFailure = (err) => ({
  type: actions.PROJECTS_FETCH_DETAIL_FAILURE,
  payload: err
})

const projectsClear = () => ({ type: actions.PROJECTS_CLEAR })
const projectsClearSelected = () => ({ type: actions.PROJECTS_CLEAR_SELECTED })
const projectsClearInfo = () => ({ type: actions.PROJECTS_CLEAR_INFO })

const projectsStartLoading = () => ({ type: actions.PROJECTS_START_LOADING })
const projectsStopLoading = () => ({ type: actions.PROJECTS_STOP_LOADING })

const projectsSendApplication = (data) => ({
  type: actions.PROJECTS_SEND_APPLICATION,
  payload: data
})
const projectsSendApplicationSuccess = (data) => ({
  type: actions.PROJECTS_SEND_APPLICATION_SUCCESS,
  payload: data
})
const projectsSendApplicationFailure = (err) => ({
  type: actions.PROJECTS_SEND_APPLICATION_FAILURE,
  payload: err
})

const projectsPatchVisited = (uuid) => ({
  type: actions.PROJECTS_PATCH_VISITED,
  payload: uuid
})
const projectsPatchVisitedSuccess = (uuid) => ({
  type: actions.PROJECTS_PATCH_VISITED_SUCCESS,
  payload: uuid
})

const projectsAddAsset = (data) => ({
  type: actions.PROJECTS_ADD_ASSET,
  payload: data
})
const projectsAddAssetSuccess = (data) => ({
  type: actions.PROJECTS_ADD_ASSET_SUCCESS,
  payload: data
})
const projectsAddAssetFailure = (err) => ({
  type: actions.PROJECTS_ADD_ASSET_FAILURE,
  payload: err
})

const projectsEditAsset = (data) => ({
  type: actions.PROJECTS_EDIT_ASSET,
  payload: data
})
const projectsEditAssetSuccess = (data) => ({
  type: actions.PROJECTS_EDIT_ASSET_SUCCESS,
  payload: data
})
const projectsEditAssetFailure = (err) => ({
  type: actions.PROJECTS_EDIT_ASSET_FAILURE,
  payload: err
})

const projectsDeleteAsset = (data) => ({
  type: actions.PROJECTS_DELETE_ASSET,
  payload: data
})
const projectsDeleteAssetSuccess = (data) => ({
  type: actions.PROJECTS_DELETE_ASSET_SUCCESS,
  payload: data
})
const projectsDeleteAssetFailure = (err) => ({
  type: actions.PROJECTS_DELETE_ASSET_FAILURE,
  payload: err
})

export {
  projectsFetchAll,
  projectsFetchAllSuccess,
  projectsFetchAllFailure,
  projectsFetchMy,
  projectsFetchMySuccess,
  projectsFetchMyFailure,
  projectsFetchDetail,
  projectsFetchDetailSuccess,
  projectsFetchDetailFailure,
  projectsClear,
  projectsClearSelected,
  projectsClearInfo,
  projectsStartLoading,
  projectsStopLoading,
  projectsSendApplication,
  projectsSendApplicationSuccess,
  projectsSendApplicationFailure,
  projectsPatchVisited,
  projectsPatchVisitedSuccess,
  projectsAddAsset,
  projectsAddAssetSuccess,
  projectsAddAssetFailure,
  projectsEditAsset,
  projectsEditAssetSuccess,
  projectsEditAssetFailure,
  projectsDeleteAsset,
  projectsDeleteAssetSuccess,
  projectsDeleteAssetFailure
}
