import React, { useEffect, useState } from 'react'
import { Button, InlineNotification, Modal } from 'carbon-components-react'
import { useDispatch, useSelector } from 'react-redux'
import { setHideModal, setShowModal } from '../../store/modal/actions'

import Logo from '../Logo'
import Auth from '../Auth'
import { actions } from '../../constants'
import { authInfoClear, authEmailVerify } from '../../store/auth/actions'

import heroImg from '../../assets/images/marketing/hero.png'
import article1 from '../../assets/images/marketing/article_1.png'
import article2 from '../../assets/images/marketing/article_2.jpg'
import article3Mob from '../../assets/images/marketing/article_3_mob.jpg'
import article3 from '../../assets/images/marketing/article_3.jpg'
import article4 from '../../assets/images/marketing/article_4.png'
import './Home.scss'

const Home = () => {
  const dispatch = useDispatch()

  const [isError, setIsError] = useState(false)
  const [auth, modal, fbAuth] = useSelector(({ auth, modal, firebase }) => [
    auth,
    modal,
    firebase.auth
  ])

  const loggedIn = fbAuth.emailVerified && !auth.isEmpty

  useEffect(() => {
    document.title = 'Lightbox | Virtual Global Agency'
    const urlParams = new URLSearchParams(window.location.search)
    const oobCode = urlParams.get('oobCode')
    const mode = urlParams.get('mode')
    if (oobCode) {
      if (mode === 'verifyEmail') {
        dispatch(authEmailVerify(oobCode))
      } else if (mode === 'resetPassword') {
        dispatch(setShowModal('changePassword'))
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (auth.response?.type === actions.AUTH_EMAIL_VERIFY_SUCCESS) {
      dispatch(authInfoClear())
      dispatch(setShowModal('finishRegister'))
    } else if (auth.error?.type === actions.AUTH_EMAIL_VERIFY_FAILURE) {
      setIsError(!!auth.error.data)
    }
  }, [auth, dispatch])

  const logo = <Logo className="logo--small" />
  const modalProps = {
    modalHeading: logo,
    open: modal.opened,
    passiveModal: true,
    primaryButtonText: 'Create account',
    onRequestClose: () => dispatch(setHideModal())
  }

  const serverError = (
    <div className="error-notifications">
      <div className="container">
        <InlineNotification
          lowContrast
          kind="error"
          iconDescription="describes the close button"
          subtitle="You have already confirmed your account. Please log in using your email and password."
          onCloseButtonClick={() => {
            setIsError(false)
            dispatch(authInfoClear())
          }}
          title=""
        />
      </div>
    </div>
  )
  return (
    <div className="marketing">
      <div className="section section--hero">
        {isError && serverError}

        <div className="container">
          <div className="hero">
            <div className="hero__visual">
              <img src={heroImg} alt="" className="hero__img" />
            </div>
            <div className="hero__info">
              <h2 className="title title--main">
                Think of Lightbox as a virtual global agency, fueled by the
                creative superpowers of Soho House members
              </h2>
              {!loggedIn && (
                <>
                  <Button
                    className="btn"
                    onClick={() => dispatch(setShowModal('register'))}
                  >
                    Join
                  </Button>
                  <span className="hero__caption">
                    Exclusively for SOHO House members.
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal id="input-modal" {...modalProps}>
        <Auth formType={modal.type} />
      </Modal>

      <div className="section section--dark section--crop">
        <div className="container">
          <div className="article  article--1 article--dark">
            <div className="article__info article__info--1">
              <h2 className="title">
                Do amazing work with incredible creators
              </h2>
              <p className="text">
                Lightbox is a collaborative platform designed to produce
                outstanding work for some of the world’s biggest brands and
                causes. Our creators are able to express interest and be
                assigned projects from a global portfolio of opportunities.
              </p>
            </div>
            <div className="article__visual">
              <img src={article1} alt="" className="article__img" />
            </div>
            <div className="article__info article__info--2">
              <h2 className="title">How it works</h2>
              <p className="text">
                Projects are submitted and published on the platform. Creators
                whose profiles qualify them to be part of the creative will see
                the project in their news feed and they can opt in to be
                selected for the job if interested.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section section--crop">
        <div className="container">
          <div className="article article--2">
            <div className="article__info">
              <h2 className="title">The power of many</h2>
              <p className="text">
                Together, we can create more impactful projects. We can also use
                our collective power to do things like provide group health
                insurance with better benefits and at a lower cost than most
                freelancers would normally be able to access. We look forward to
                rolling out benefits as soon as we add enough members to justify
                them.
              </p>
            </div>
            <div className="article__visual">
              <img src={article2} alt="" className="article__img" />
            </div>
          </div>
          <div className="article article--3">
            <div className="article__info">
              <h2 className="title">Create a meaningful difference</h2>
              <p className="text">
                We believe in the power of bringing the most creative minds to
                the most urgent causes in order to create real change. Lightbox
                members agree to spend some time each year on social impact
                projects.
              </p>
            </div>
            <div className="article__visual">
              <img
                src={article3}
                alt=""
                className="article__img article__img--show-md"
              />
              <img
                src={article3Mob}
                alt=""
                className="article__img article__img--hide-md"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section section--dark section--crop">
        <div className="container">
          <div className="article article--dark article--4">
            <div className="article__info">
              <h2 className="title">Be one of the first to flip the switch</h2>
              <p className="text">
                We&apos;re launching Lightbox to a select group of Soho House
                creators. We&apos;re inviting you to sign up, collaborate on a
                project or two (get paid, of course) and help us refine Lightbox
                before we roll it out to the larger Soho House membership. Join
                us today and let&apos;s get on with changing the world!
              </p>
              <p className="text text--small">
                Lightbox is not a part of Soho House. It&apos;s a joint venture
                between us and a group of experienced agency principals, who see
                in you the future of how creative gets done.
              </p>
            </div>
            <div className="article__visual">
              <img src={article4} alt="" className="article__img" />
            </div>
          </div>

          {!loggedIn && <Auth formType="register" dark={true} />}
        </div>
      </div>
    </div>
  )
}

export default Home
