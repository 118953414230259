import { addMethod, mixed } from 'yup'

// eslint-disable-next-line no-useless-escape
export const phone = (string) => /[0-9]*$/.test(string)

addMethod(mixed, 'phone', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: 'Phone number should contain only numbers.',
    test: (value) => phone(value)
  })
})
