import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const projectUuid = urlParams.get('projectUuid')
  const auth = useSelector(({ firebase }) => firebase.auth)
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const { path } = rest
        if (auth.isLoaded) {
          if (!auth.emailVerified || auth.isEmpty) {
            if (path === '/') {
              return <RouteComponent {...routeProps} />
            } else {
              return <Redirect to={'/'} />
            }
          } else {
            if (projectUuid) {
              return (
                <Redirect to={`/projects/${projectUuid}?tab=my-projects`} />
              )
            }
            return <RouteComponent {...routeProps} />
          }
        } else {
          return <div style={{ minHeight: '100vh' }}></div>
        }
      }}
    />
  )
}

export default PrivateRoute
