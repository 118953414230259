import React from 'react'

import { LogoInstagram20, Email20 } from '@carbon/icons-react'
import SocialLink from '../../shared/SocialLink'

import './SocialLinks.scss'

const SocialLinks = () => {
  const links = [
    {
      name: 'instagram',
      href: 'https://www.instagram.com/lightboxglobal/',
      icon: <LogoInstagram20 />
    },
    {
      name: 'email',
      href: 'mailto:support@lightboxglobal.com',
      icon: <Email20 />
    }
  ]

  const linksList = links.map((link) => {
    const { name, href, icon } = link
    return (
      <li key={name} className="socials__item">
        <SocialLink link={href} icon={icon} />
      </li>
    )
  })

  return (
    <div className="socials">
      <span className="socials__label">Connect</span>
      <ul className="socials__list">{linksList}</ul>
    </div>
  )
}

export default SocialLinks
