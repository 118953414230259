import React, { useEffect } from 'react'
import { Switch, withRouter } from 'react-router-dom'

import Header from '../Header'
import Home from '../Home'
import Footer from '../Footer'
import Projects from '../Projects'
import PrivateRoute from '../hoc/PrivateRoute'
import Profile from '../Profile'
import ProjectDetail from '../ProjectDetail'

import './App.scss'

const App = ({ history }) => {
  const isHome = history.location.pathname === '/'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [history.location.pathname])

  return (
    <div className="app">
      <Header />
      {!isHome && <Profile />}
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/projects" component={Projects} />
        <PrivateRoute
          exact
          path="/projects/:uuid"
          component={({ match }) => (
            <ProjectDetail projectId={match.params.uuid} />
          )}
        />
      </Switch>
      <Footer />
    </div>
  )
}

export default withRouter(App)
