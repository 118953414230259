import { actions } from '../../constants'

export const defaultState = {
  user: null,
  loading: false,
  response: null,
  error: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.USER_FETCH_SUCCESS:
      return { ...state, user: action.payload }
    case actions.USER_CLEAR:
      return defaultState
    case actions.USER_START_LOADING:
      return { ...state, loading: true }
    case actions.USER_STOP_LOADING:
      return { ...state, loading: false }
    case actions.USER_SET_ERROR:
      return { ...state, error: action.payload }
    case actions.USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
        response: {
          type: actions.USER_UPDATE_SUCCESS,
          response: action.payload
        }
      }
    case actions.USER_UPDATE_FAILURE:
      return {
        ...state,
        error: { type: actions.USER_UPDATE_FAILURE, data: action.payload }
      }
    case actions.USER_INFO_CLEAR:
      return { ...state, response: null, error: null }
    default:
      return state
  }
}
