import React from 'react'
import { useState } from 'react'

import { storage } from '../../../services/firebase'
import { Button, FileUploader, InlineLoading } from 'carbon-components-react'

import no_photo from '../../../assets/images/no_photo.png'
import './ImageUpload.scss'
import { useSelector } from 'react-redux'

const ImageUpload = ({ sendImageUrl }) => {
  const userPhoto = useSelector((state) =>
    state.user.user?.photo ? state.user.user.photo : ''
  )

  const [imageAsFile, setImageAsFile] = useState('')
  const [imageAsUrl, setImageAsUrl] = useState({ imgUrl: '' })
  const [loading, setLoading] = useState({ status: '', description: '' })

  const photo = userPhoto || no_photo
  const uploadBtnClassList = `image-upload__btn bx--btn--mt-3 ${
    imageAsFile ? '' : 'bx--btn--hide'
  }`

  const handleImageAsFile = (e) => {
    const image = e.target.files[0]
    setImageAsFile(image)
  }

  const handleOnDelete = (e) => {
    e.preventDefault()
    setImageAsFile('')
    setLoading({ status: '', description: '' })
  }

  const onSuccess = () => {
    setLoading({ status: '', description: '' })
  }

  const handleFireBaseUpload = (e) => {
    e.preventDefault()
    setLoading({ status: 'active', description: 'Uploading...' })
    console.log('start of upload')
    // async magic goes here...
    if (imageAsFile === '') {
      setLoading({ status: 'error', description: 'Not an image' })
      console.error(`not an image, the image file is a ${typeof imageAsFile}`)
      return
    }
    console.log(imageAsFile.type)
    if (imageAsFile.type !== 'image/jpeg' && imageAsFile.type !== 'image/png') {
      setLoading({ status: 'error', description: 'Not an image' })
      return
    }
    const uploadTask = storage
      .ref(`/images/${imageAsFile.name}`)
      .put(imageAsFile)
    //initiates the firebase side uploading
    uploadTask.on(
      'state_changed',
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot)
      },
      (err) => {
        //catches the errors
        console.log(err)
        setLoading({ status: 'error', description: err.message })
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref('images')
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setImageAsUrl((prevObject) => ({
              ...prevObject,
              imgUrl: fireBaseUrl
            }))
            console.log(fireBaseUrl)
            setLoading({ status: 'finished', description: 'Image uploaded.' })
            sendImageUrl(fireBaseUrl)
          })
      }
    )
  }

  return (
    <div className="image-upload">
      <div className="image-upload__photo-wrap">
        <img
          src={imageAsUrl.imgUrl || photo}
          className="image-upload__photo"
          alt="Profile"
        />
      </div>
      <form onSubmit={handleFireBaseUpload}>
        <div className="image-upload__uploader bx--file__container">
          <FileUploader
            accept={['.jpg', '.png']}
            buttonKind="secondary"
            buttonLabel="Change Photo"
            filenameStatus="edit"
            iconDescription="Clear file"
            onChange={handleImageAsFile}
            onDelete={handleOnDelete}
          />
        </div>
        <div className="l-btn-wrap">
          {loading.status && (
            <InlineLoading
              status={loading.status}
              onSuccess={onSuccess}
              successDelay={1500}
              description={loading.description}
            />
          )}
          <Button type="submit" className={uploadBtnClassList}>
            Upload
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ImageUpload
