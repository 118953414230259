import React from 'react'
import { Button } from 'carbon-components-react'
import { useDispatch } from 'react-redux'

import { setHideModal } from '../../../store/modal/actions'

import './AccountVerify.scss'

const VerifyAccount = () => {
  const dispatch = useDispatch()

  return (
    <>
      <h3 className="auth__title">Create your Lightbox account.</h3>
      <span className="auth__steps">Step 1 of 2</span>
      <p className="auth__text auth__text--mb-1">
        Please check your email and click the link to verify yourself. You may
        then customize your Lightbox account and complete the membership
        process.
      </p>
      <div className="l-btn-wrap">
        <Button type="button" onClick={() => dispatch(setHideModal())}>
          Ok
        </Button>
      </div>
    </>
  )
}

export default VerifyAccount
