import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'carbon-components-react'
import { useLocation } from 'react-router-dom'

import {
  projectsClearSelected,
  projectsFetchDetail
} from '../../store/projects/actions'
import ProjectDetailSkeleton from './ProjectDetailSkeleton'
import NotAssigned from './NotAssigned'
import Assigned from './Assigned'

import './ProjectDetail.scss'
import { setHideModal } from '../../store/modal/actions'

const ProjectDetail = ({ projectId }) => {
  const [isApplication, setIsApplication] = useState(false)
  const dispatch = useDispatch()
  const project = useSelector(({ projects }) => projects.selected)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.has('tab') && searchParams.get('tab')
  const isAssigned = !!project?.creators

  useEffect(() => {
    dispatch(projectsFetchDetail(`${projectId}?tab=${tab}`))
  }, [projectId, tab, dispatch])

  useEffect(() => {
    dispatch(setHideModal())
    return () => dispatch(projectsClearSelected())
    // eslint-disable-next-line
  }, [])

  const showApplication = () => setIsApplication(!isApplication)

  if (project) {
    const hero = (
      <div
        className="c-hero"
        style={{ backgroundImage: `url(${project.bannerPhoto})` }}
      >
        <div className="container">
          <div className="c-hero__info">
            <h1 className="c-title">{project.name}</h1>
          </div>
          {!(isAssigned || isApplication) && (
            <Button
              kind="secondary"
              onClick={showApplication}
              className="bx--btn--white bx--btn--hide bx--btn--show-md"
            >
              I&apos;m Interested
            </Button>
          )}
        </div>
      </div>
    )

    if (isAssigned) {
      return (
        <div className="project-detail">
          {hero}
          <Assigned project={project} />
        </div>
      )
    } else {
      return (
        <div className="project-detail">
          {hero}
          <NotAssigned
            project={project}
            isApplication={isApplication}
            toggleApplication={() => setIsApplication(!isApplication)}
          />
        </div>
      )
    }
  } else {
    return <ProjectDetailSkeleton isMyProject={tab === 'my-projects'} />
  }
}

export default ProjectDetail
