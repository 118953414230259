import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Form,
  InlineLoading,
  TextArea,
  TextInput
} from 'carbon-components-react'
import { userInfoClear, userUpdate } from 'store/user/actions'
import { showProfileAfterLogin } from 'store/profile/actions'
import { setShowModal } from 'store/modal/actions'
import { capitalize } from 'utils/general'
import actions from 'constants/actions'
import '../../../validations'
import './FinishRegister.scss'

const FinishRegister = () => {
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user)

  const [loading, setLoading] = useState({ status: '', description: '' })

  const formik = useFormik({
    initialValues: {
      primarySkills: '',
      secondarySkills: '',
      website: '',
      supportedCauses: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      primarySkills: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('This field is required.'),
      secondarySkills: Yup.string(),
      website: Yup.string().website(),
      supportedCauses: Yup.string()
    }),
    onSubmit: (values) => onFormSubmit(values)
  })

  const { values, handleChange, touched, handleBlur, handleSubmit } = formik

  let timeout = useRef(null)

  useEffect(() => {
    if (formik.submitCount) {
      if (user.loading) {
        setLoading({ status: 'active', description: 'Loading...' })
      }
      if (user.response?.type === actions.USER_UPDATE_SUCCESS) {
        setLoading({ status: 'finished', description: 'Fields saved.' })
      } else if (user.error) {
        setLoading({ status: 'error', description: user.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(userInfoClear())
        }, 3000)
      }
    }
  }, [user, dispatch, formik.submitCount, timeout])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const onFormSubmit = (values) => dispatch(userUpdate(values))

  const onSuccess = () => {
    window.location = '/projects'
  }

  const handleCapital = (e) => {
    e.preventDefault()
    formik.setFieldValue(e.target.id, capitalize(e.target.value))
  }

  const fieldsList = [
    {
      id: 'primarySkills',
      className: 'auth__input',
      value: values.primarySkills,
      invalid: !!formik.errors.primarySkills && touched.primarySkills,
      invalidText: formik.errors.primarySkills,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Primary Skill',
      placeholder: 'Skill',
      touched: String(touched.primarySkills)
    },
    {
      id: 'secondarySkills',
      className: 'auth__input',
      value: values.secondarySkills,
      invalid: !!formik.errors.secondarySkills && touched.secondarySkills,
      invalidText: formik.errors.secondarySkills,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Secondary Skill',
      placeholder: 'Skill',
      touched: String(touched.secondarySkills)
    },
    {
      id: 'website',
      className: 'auth__input',
      value: values.website,
      invalid: !!formik.errors.website && touched.website,
      invalidText: formik.errors.website,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Website',
      placeholder: 'Enter website, portfolio, linkedin URL',
      touched: String(touched.website)
    },
    {
      id: 'supportedCauses',
      className: 'auth__input',
      value: values.supportedCauses,
      invalid: !!formik.errors.supportedCauses && touched.supportedCauses,
      invalidText: formik.errors.supportedCauses,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Causes I Support',
      touched: String(touched.supportedCauses)
    }
  ]

  const fields = fieldsList.map((field, key) => {
    if (field.id === 'supportedCauses') {
      return <TextArea key={key} {...field} />
    } else {
      return <TextInput key={key} {...field} />
    }
  })

  const handleLogIn = useCallback(() => {
    batch(() => {
      dispatch(setShowModal('logIn'))
      dispatch(showProfileAfterLogin(true))
    })
  }, [dispatch])

  const renderSkillsForm = useMemo(() => {
    if (user.user) {
      return (
        <Fragment>
          <h3 className="auth__title">
            Finish customizing your Lightbox account.
          </h3>
          <span className="auth__steps">Step 2 of 2</span>
          <Form className="auth__form" onSubmit={handleSubmit}>
            <div className="l-form-wrap">{fields}</div>
            <div className="l-btn-wrap">
              {loading.status && (
                <InlineLoading
                  status={loading.status}
                  onSuccess={onSuccess}
                  successDelay={1500}
                  description={loading.description}
                />
              )}
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Fragment>
      )
    }

    if (!user.user) {
      return (
        <Fragment>
          <h3 className="auth__title">
            Thanks! Your email has been confirmed.
          </h3>
          <span className="auth__steps">
            Please, log in to complete your profile so you can join projects.
          </span>
          <Button onClick={handleLogIn}>Log in</Button>
        </Fragment>
      )
    }
  }, [
    user.user,
    fields,
    handleSubmit,
    loading.description,
    loading.status,
    handleLogIn
  ])

  return <>{renderSkillsForm}</>
}

export default FinishRegister
