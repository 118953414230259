import { actions } from '../../constants'

const defaultState = {
  saveBtn: false,
  backBtn: false,
  action: null,
  menuOpened: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.HEADER_SHOW_CHANGE_PASSWORD:
      return {
        ...state,
        saveBtn: true,
        backBtn: true,
        action: 'showChangePassword'
      }
    case actions.HEADER_CHANGE_PASSWORD:
      return { ...state, action: 'changePassword' }
    case actions.HEADER_GO_BACK:
      return { ...state, action: action.type }
    case actions.HEADER_CLEAR_ACTION:
      return { ...state, action: null }
    case actions.HEADER_TOGGLE_MENU:
      return { ...state, menuOpened: !state.menuOpened }
    case actions.HEADER_PROJECT:
      return { ...defaultState, backBtn: true }
    case actions.HEADER_MENU_OPENED:
      return { menuOpened: true, saveBtn: false, backBtn: false, action: null }
    case actions.HEADER_DEFAULT:
      return defaultState
    default:
      return state
  }
}
