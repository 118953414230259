import React, { useState, useEffect } from 'react'
import {
  Button,
  Form,
  InlineLoading,
  TextArea,
  TextInput
} from 'carbon-components-react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import ImageUpload from './ImageUpload'
import { userInfoClear, userUpdate } from '../../store/user/actions'
import ChangePassword from './ChangePassword'
import {
  headerShowChangePassword,
  headerToggleMenu
} from '../../store/header/actions'
import '../../validations'
import { actions } from '../../constants'
import { capitalize } from '../../utils/general'
import Footer from '../Footer'
import environment from '../../environment/environment'

import './Profile.scss'
import { showProfileAfterLogin } from '../../store/profile/actions'
import { authLogout } from '../../store/auth/actions'

const Profile = () => {
  const dispatch = useDispatch()

  const [
    user,
    headerAction,
    isOpened,
    showProfile
  ] = useSelector(({ user, header, profile }) => [
    user,
    header.action,
    header.menuOpened,
    profile.showProfileAfterLogin
  ])

  const [changePassword, setChangePassword] = useState(false)
  const [loading, setLoading] = useState({ status: '', description: '' })

  const currUser = user.user

  const profileClassList = `profile ${isOpened ? 'js-opened' : ''}`

  const onSubmit = (values) => {
    const formData = {
      ...values,
      phone: String(values.phone)
    }
    dispatch(userUpdate(formData))
  }

  const formik = useFormik({
    initialValues: {
      firstName: currUser?.firstName ? currUser.firstName : '',
      lastName: currUser?.lastName ? currUser.lastName : '',
      email: currUser?.email ? currUser.email : '',
      phone: currUser?.phone ? currUser.phone : '',
      primarySkills: currUser?.primarySkills ? currUser.primarySkills : '',
      secondarySkills: currUser?.secondarySkills
        ? currUser.secondarySkills
        : '',
      website: currUser?.website ? currUser.website : '',
      photo: currUser?.photo ? currUser.photo : '',
      rate: currUser?.rate ? currUser.rate : '',
      supportedCauses: currUser?.supportedCauses
        ? currUser.supportedCauses
        : '',
      sohoHouseMemberNumber: currUser?.sohoHouseMemberNumber
        ? currUser.sohoHouseMemberNumber
        : ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('Field is required.'),
      lastName: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('This field is required.'),
      phone: Yup.string().phone().required('This field is required.'),
      primarySkills: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('This field is required.'),
      secondarySkills: Yup.string(),
      website: Yup.string().website(),
      rate: Yup.number(
        'This field should contain only whole numbers.'
      ).required('This field is required.'),
      supportedCauses: Yup.string(),
      sohoHouseMemberNumber: Yup.number().required('This field is required.')
    }),
    onSubmit
  })

  const {
    values,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    initialValues
  } = formik

  useEffect(() => {
    if (headerAction === 'showChangePassword') {
      setChangePassword(true)
    }
    if (headerAction === null) {
      setChangePassword(false)
    }
  }, [headerAction])

  useEffect(() => {
    if (showProfile) {
      if (currUser && initialValues.firstName !== '') {
        dispatch(showProfileAfterLogin(false))
        handleSubmit()
      } else {
        if (!isOpened) {
          dispatch(headerToggleMenu())
        }
      }
    }
  }, [dispatch, showProfile, currUser, handleSubmit, initialValues, isOpened])

  useEffect(() => {
    if (formik.submitCount) {
      if (user.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      }
      if (user.response?.type === actions.USER_UPDATE_SUCCESS) {
        setLoading({
          status: 'finished',
          description: 'Profile information updated.'
        })
      } else if (user.error) {
        setLoading({ status: 'error', description: user.error.data })
      }
    }
  }, [user, formik.submitCount])

  const onGetImageUrl = (url) => {
    if (url) {
      formik.setFieldValue('photo', url)
    }
  }

  const onSuccess = () => {
    setLoading({ status: '', description: '' })
    dispatch(userInfoClear())
  }

  const onKeyPress = (e) => {
    e.preventDefault()
    const value = e.key.replace(/[^0-9]*/g, '')
    formik.setFieldValue(e.target.id, e.target.value + value)
  }

  const handleCapital = (e) => {
    e.preventDefault()
    formik.setFieldValue(e.target.id, capitalize(e.target.value))
  }

  const fieldsList = [
    {
      id: 'photo',
      value: values.photo,
      labelText: 'Photo',
      hideLabel: true,
      hidden: true
    },
    {
      id: 'firstName',
      className: 'auth__input',
      value: values.firstName,
      invalid: !!formik.errors.firstName && touched.firstName,
      invalidText: formik.errors.firstName,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'First Name',
      placeholder: 'Name',
      touched: String(touched.firstName)
    },
    {
      id: 'lastName',
      className: 'auth__input',
      value: values.lastName,
      invalid: !!formik.errors.lastName && touched.lastName,
      invalidText: formik.errors.lastName,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Last Name',
      placeholder: 'Name',
      touched: String(touched.lastName)
    },
    {
      id: 'email',
      type: 'email',
      className: 'auth__input',
      value: values.email,
      labelText: 'Email',
      disabled: true
    },
    {
      id: 'phone',
      className: 'auth__input',
      value: values.phone,
      invalid: !!formik.errors.phone && touched.phone,
      invalidText: formik.errors.phone,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Phone',
      placeholder: 'Number',
      touched: String(touched.phone)
    },
    {
      id: 'primarySkills',
      className: 'auth__input',
      value: values.primarySkills,
      invalid: !!formik.errors.primarySkills && touched.primarySkills,
      invalidText: formik.errors.primarySkills,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Primary Skill',
      placeholder: 'Skill',
      touched: String(touched.primarySkills)
    },
    {
      id: 'secondarySkills',
      className: 'auth__input',
      value: values.secondarySkills,
      invalid: !!formik.errors.secondarySkills && touched.secondarySkills,
      invalidText: formik.errors.secondarySkills,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Secondary Skill',
      placeholder: 'Skill',
      touched: String(touched.secondarySkills)
    },
    {
      id: 'sohoHouseMemberNumber',
      type: 'number',
      className: 'auth__input',
      value: values.sohoHouseMemberNumber,
      invalid:
        !!formik.errors.sohoHouseMemberNumber && touched.sohoHouseMemberNumber,
      invalidText: formik.errors.sohoHouseMemberNumber,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'SoHo House Member Number',
      placeholder: 'Number',
      touched: String(touched.sohoHouseMemberNumber)
    },
    {
      id: 'website',
      className: 'auth__input',
      value: values.website,
      invalid: !!formik.errors.website && touched.website,
      invalidText: formik.errors.website,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Website',
      placeholder: 'Enter website, portfolio, linkedin URL',
      touched: String(touched.website)
    },
    {
      id: 'supportedCauses',
      className: 'auth__input',
      value: values.supportedCauses,
      invalid: !!formik.errors.supportedCauses && touched.supportedCauses,
      invalidText: formik.errors.supportedCauses,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Causes I Support',
      touched: String(touched.supportedCauses)
    },
    {
      id: 'rate',
      type: 'number',
      className: 'auth__input',
      helperText: '$USD',
      value: values.rate,
      invalid: !!formik.errors.rate && touched.rate,
      invalidText: formik.errors.rate,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyPress: onKeyPress,
      labelText: 'Hourly Rate',
      placeholder: 'Rate',
      touched: String(touched.rate)
    }
  ]

  const fields = fieldsList.map((field, key) => {
    if (field.id === 'rate') {
      return (
        <div key={key} className="profile__new-row">
          <Button
            kind="secondary"
            onClick={() => dispatch(headerShowChangePassword())}
          >
            Change Password
          </Button>
          <h2 className="c-title">Billing</h2>
          <TextInput {...field} />
        </div>
      )
    }
    if (field.id === 'photo') {
      return (
        <div className="profile__hidden" key={key}>
          <TextInput {...field} />
        </div>
      )
    }
    if (field.id === 'supportedCauses') {
      return <TextArea key={key} {...field} />
    }
    return <TextInput key={key} {...field} />
  })

  const profile = (
    <>
      <div className="container">
        <h2 className="c-title">Navigation</h2>
        <div className="l-list">
          <div className="l-list__item">
            {currUser?.role !== 'creator' && (
              <Button
                type="button"
                href={environment.adminUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                Admin Portal
              </Button>
            )}
          </div>
          <div className="l-list__item">
            <Button kind="secondary" onClick={() => dispatch(authLogout())}>
              Sign Out
            </Button>
          </div>
        </div>
        <h2 className="c-title">Profile</h2>
        <ImageUpload sendImageUrl={onGetImageUrl} />
        <Form className="profile__form" onSubmit={handleSubmit}>
          <div className="l-form-wrap">{fields}</div>
          <div className="l-btn-wrap">
            {loading.status && (
              <InlineLoading
                status={loading.status}
                onSuccess={onSuccess}
                successDelay={1500}
                description={loading.description}
              />
            )}
            <Button
              type="submit"
              disabled={!formik.dirty}
              className="bx--btn--mt-3"
            >
              Save changes
            </Button>
          </div>
        </Form>
      </div>
      <Footer />
    </>
  )

  return (
    <div className={profileClassList}>
      {!changePassword && profile}
      {changePassword && <ChangePassword />}
    </div>
  )
}

export default Profile
