import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, InlineLoading, TextInput } from 'carbon-components-react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import {
  authInfoClear,
  authPasswordResetEmail
} from '../../../store/auth/actions'
import { setHideModal } from '../../../store/modal/actions'
import { actions } from '../../../constants'

import './ForgotPassword.scss'

const ForgotPassword = () => {
  const dispatch = useDispatch()

  const auth = useSelector(({ auth }) => auth)

  const [loading, setLoading] = useState({ status: '', description: '' })
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Please enter valid email address.')
        .required('This field is required.')
    }),
    onSubmit: (values) => onFormSubmit(values)
  })

  const { values, handleChange, touched, handleBlur, handleSubmit } = formik

  let timeout = useRef(null)

  useEffect(() => {
    if (formik.submitCount) {
      if (auth.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      }
      if (auth.response?.type === actions.AUTH_PASSWORD_RESET_EMAIL_SUCCESS) {
        setLoading({ status: '', description: '' })
        setSuccess(true)
      } else if (auth.error) {
        setLoading({ status: 'error', description: auth.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(authInfoClear())
        }, 3000)
      }
    }
  }, [auth, dispatch, formik.submitCount])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const onFormSubmit = (values) => dispatch(authPasswordResetEmail(values))

  const fieldsList = [
    {
      id: 'email',
      type: 'email',
      className: 'auth__input',
      value: values.email,
      invalid: !!formik.errors.email && touched.email,
      invalidText: formik.errors.password,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Email',
      placeholder: 'Email',
      touched: String(touched.password)
    }
  ]

  // Todo: export as util
  const fields = fieldsList.map((field, key) => (
    <TextInput key={key} {...field} />
  ))

  const resetForm = (
    <>
      <h3 className="auth__title auth__title--standalone">
        Enter your email to reset password.
      </h3>
      <Form className="auth__form" onSubmit={handleSubmit}>
        <div className="l-form-wrap">{fields}</div>
        <div className="l-btn-wrap">
          {loading.status && (
            <InlineLoading
              status={loading.status}
              description={loading.description}
            />
          )}
          <Button type="submit">Send</Button>
        </div>
      </Form>
    </>
  )

  const successItem = (
    <>
      <h3 className="auth__title auth__title--standalone">Reset Password.</h3>
      <p className="auth__text auth__text--mb-1">
        Check your mailbox. A login link was sent to {values.email}
      </p>
      <div className="l-btn-wrap">
        <Button type="button" onClick={() => dispatch(setHideModal())}>
          Ok
        </Button>
      </div>
    </>
  )

  return success ? successItem : resetForm
}

export default ForgotPassword
