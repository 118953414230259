import React from 'react'

import './Logo.scss'
import logo from '../../assets/images/lightbox.svg'
import logoLight from '../../assets/images/lightbox_light.svg'
import logoShort from '../../assets/images/lbx.svg'
import logoShortLight from '../../assets/images/lbx_light.svg'

const Logo = ({ light, className }) => {
  const classList = `logo ${className ? className : ''}`
  return (
    <div className={classList}>
      <img
        src={light ? logoLight : logo}
        alt="Lightbox logo"
        className="logo__img"
      />
      <img
        src={light ? logoShortLight : logoShort}
        alt="Lightbox logo mobile"
        className="logo__img--mob"
      />
    </div>
  )
}

export default Logo
