import React from 'react'
import ReactDOM from 'react-dom'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './components/App/App'
import firebase, { firebaseConfig } from './services/firebase'
import store from './store'

import './index.scss'

const rrfProps = {
  firebase,
  config: firebaseConfig,
  dispatch: store.dispatch
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router>
        <App />
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
)
