import errors from '../constants/errors'

export function convertBackendResponse(msg) {
  switch (msg) {
    case 'auth/weak-password':
      return errors.WEAK_PASSWORD
    case 'auth/too-many-requests':
      return errors.TOO_MANY_REQUESTS
    case 'auth/wrong-password':
      return errors.PASSWORD_IS_INVALID
    case 'auth/user-not-found':
      return errors.USER_NOT_FOUND
    case 'auth/invalid-email':
      return errors.INVALID_EMAIL
    case 'auth/email-already-in-use':
      return errors.EMAIL_ALREADY_IN_USE
    case 'auth/verify-your-email':
      return errors.VERIFY_YOUR_EMAIL
    case 'auth/expired-action-code':
      return errors.EXPIRED_ACTION_CODE
    case 'auth/invalid-action-code':
      return errors.INVALID_ACTION_CODE
    case 'website must be an URL address':
      return errors.ENTER_VALID_WEBSITE_URL
    case 'url must be an URL address':
      return errors.INVALID_URL
    default:
      return msg
  }
}

export const parse = (error) => {
  let err = errors.OOPS

  if (error.code) {
    err = convertBackendResponse(error.code)
  }

  if (error.statusCode && error.message) {
    err = convertBackendResponse(
      Array.isArray(error.message) ? error.message[0] : error.message
    )
  }

  return err
}

export default {
  parse
}
