import React from 'react'
import { Link } from 'react-router-dom'

import SocialLinks from './SocialLinks'
import Logo from '../Logo'

import './Footer.scss'

const Footer = () => {
  const date = new Date().getFullYear()

  return (
    <footer className="footer">
      Footer is here
      <div className="container">
        <SocialLinks />
        <Link to={'/'}>
          <Logo light={true} className="logo--footer" />
        </Link>
        <div className="footer__info">
          <Link to={'/'} className="footer__link">
            www.lightboxglobal.com
          </Link>
          <span className="footer__copy">Copyright {date}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
