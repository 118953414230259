import { actions } from '../../constants'

const authRegister = (data) => ({ type: actions.AUTH_REGISTER, payload: data })
const authRegisterSuccess = (data) => ({
  type: actions.AUTH_REGISTER_SUCCESS,
  payload: data
})
const authRegisterFailure = (error) => ({
  type: actions.AUTH_REGISTER_FAILURE,
  payload: error
})

const authLogin = (data) => ({ type: actions.AUTH_LOGIN, payload: data })
const authLoginSuccess = (data) => ({
  type: actions.AUTH_LOGIN_SUCCESS,
  payload: data
})
const authLoginFailure = (error) => ({
  type: actions.AUTH_LOGIN_FAILURE,
  payload: error
})

const authLogout = () => ({ type: actions.AUTH_LOGOUT })
const authLogoutSuccess = (data) => ({
  type: actions.AUTH_LOGOUT_SUCCESS,
  payload: data
})
const authLogoutFailure = (error) => ({
  type: actions.AUTH_LOGOUT_FAILURE,
  payload: error
})

const authEmailVerify = (data) => ({
  type: actions.AUTH_EMAIL_VERIFY,
  payload: data
})
const authEmailVerifySuccess = (data) => ({
  type: actions.AUTH_EMAIL_VERIFY_SUCCESS,
  payload: data
})
const authEmailVerifyFailure = (error) => ({
  type: actions.AUTH_EMAIL_VERIFY_FAILURE,
  payload: error
})

const authPasswordResetEmail = (data) => ({
  type: actions.AUTH_PASSWORD_RESET_EMAIL,
  payload: data
})
const authPasswordResetEmailSuccess = (data) => ({
  type: actions.AUTH_PASSWORD_RESET_EMAIL_SUCCESS,
  payload: data
})
const authPasswordResetEmailFailure = (error) => ({
  type: actions.AUTH_PASSWORD_RESET_EMAIL_FAILURE,
  payload: error
})

const authPasswordReset = (data) => ({
  type: actions.AUTH_PASSWORD_RESET,
  payload: data
})
const authPasswordResetSuccess = (data) => ({
  type: actions.AUTH_PASSWORD_RESET_SUCCESS,
  payload: data
})
const authPasswordResetFailure = (error) => ({
  type: actions.AUTH_PASSWORD_RESET_FAILURE,
  payload: error
})

const authChangeEmail = (data) => ({
  type: actions.AUTH_CHANGE_EMAIL,
  payload: data
})
const authChangeEmailSuccess = (data) => ({
  type: actions.AUTH_CHANGE_EMAIL_SUCCESS,
  payload: data
})
const authChangeEmailFailure = (error) => ({
  type: actions.AUTH_CHANGE_EMAIL_FAILURE,
  payload: error
})

const authStartLoading = () => ({ type: actions.AUTH_START_LOADING })
const authStopLoading = () => ({ type: actions.AUTH_STOP_LOADING })

const authInfoClear = () => ({ type: actions.AUTH_INFO_CLEAR })

const authChangePassword = (data) => ({
  type: actions.AUTH_CHANGE_PASSWORD,
  payload: data
})
const authChangePasswordSuccess = (data) => ({
  type: actions.AUTH_CHANGE_PASSWORD_SUCCESS,
  payload: data
})
const authChangePasswordFailure = (data) => ({
  type: actions.AUTH_CHANGE_PASSWORD_FAILURE,
  payload: data
})

const authSetRegister = () => ({ type: actions.AUTH_SET_REGISTER })
const authClearRegister = () => ({ type: actions.AUTH_CLEAR_REGISTER })

export {
  authRegister,
  authRegisterSuccess,
  authRegisterFailure,
  authLogin,
  authLoginSuccess,
  authLoginFailure,
  authLogout,
  authLogoutSuccess,
  authLogoutFailure,
  authEmailVerify,
  authEmailVerifySuccess,
  authEmailVerifyFailure,
  authPasswordResetEmail,
  authPasswordResetEmailSuccess,
  authPasswordResetEmailFailure,
  authChangeEmail,
  authChangeEmailSuccess,
  authChangeEmailFailure,
  authPasswordReset,
  authPasswordResetSuccess,
  authPasswordResetFailure,
  authInfoClear,
  authStartLoading,
  authStopLoading,
  authChangePassword,
  authChangePasswordSuccess,
  authChangePasswordFailure,
  authSetRegister,
  authClearRegister
}
