import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Form,
  InlineLoading,
  Modal,
  Select,
  SelectItem,
  TextInput
} from 'carbon-components-react'
import { useDispatch, useSelector } from 'react-redux'

import './Application.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { actions } from '../../../../constants'
import { capitalize } from '../../../../utils/general'
import {
  projectsClearInfo,
  projectsSendApplication
} from '../../../../store/projects/actions'
import { setHideModal, setShowModal } from '../../../../store/modal/actions'
import Logo from '../../../Logo'

const Application = ({ positions, close }) => {
  const dispatch = useDispatch()

  const [projects, user, modal] = useSelector(({ projects, user, modal }) => [
    projects,
    user.user,
    modal
  ])

  const [loading, setLoading] = useState({ status: '', description: '' })

  const formik = useFormik({
    initialValues: {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      position: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('Field is required.'),
      email: Yup.string()
        .email('Please enter valid email address.')
        .required('This field is required.'),
      position: Yup.string().required('This field is required.')
    }),
    onSubmit: (values) => onFormSubmit(values)
  })

  const { values, handleChange, touched, handleBlur, handleSubmit } = formik

  let timeout = useRef(null)

  useEffect(() => {
    if (formik.submitCount) {
      if (projects.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      }
      if (
        projects.response?.type === actions.PROJECTS_SEND_APPLICATION_SUCCESS
      ) {
        setLoading({ status: 'finished', description: 'Application sent.' })
        dispatch(projectsClearInfo())
      } else if (projects.error) {
        setLoading({ status: 'error', description: projects.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(projectsClearInfo())
        }, 3000)
      }
    }
  }, [projects, dispatch, formik.submitCount])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const handleCapital = (e) => {
    e.preventDefault()
    formik.setFieldValue(e.target.id, capitalize(e.target.value))
  }

  const onSuccess = () => {
    setLoading({ status: '', description: '' })
    dispatch(projectsClearInfo())
    dispatch(setShowModal('any'))
  }

  const onFormSubmit = (values) => {
    const data = {
      uuid: projects.selected.uuid,
      application: {
        name: values.name,
        email: values.email,
        positionUuid: values.position
      }
    }
    dispatch(projectsSendApplication(data))
  }

  const selectItems = positions.map((pos) => {
    return <SelectItem key={pos.uuid} value={pos.uuid} text={pos.name} />
  })

  const handleModalClose = () => {
    dispatch(setHideModal())
    close()
  }

  const modalProps = {
    modalHeading: <Logo className="logo--small" />,
    open: modal.opened,
    passiveModal: true,
    primaryButtonText: 'Ok',
    onRequestClose: handleModalClose
  }

  return (
    <>
      <Modal id="input-modal" {...modalProps}>
        <div className="auth">
          <h3 className="auth__title">{projects.selected.name} Application</h3>
          <p className="auth__text">
            Thank you for your interest. A Project Manager will review your
            application and be in touch soon.
          </p>
          <div className="l-btn-wrap">
            <Button type="button" onClick={handleModalClose}>
              Ok
            </Button>
          </div>
        </div>
      </Modal>
      <div className="container">
        <h2 className="c-title">Application</h2>
        <Form className="profile__form" onSubmit={handleSubmit}>
          <div className="l-form-wrap">
            <TextInput
              id="name"
              className="auth__input"
              value={values.name}
              invalid={!!formik.errors.name && touched.name}
              invalidText={formik.errors.name}
              onBlur={handleBlur}
              onKeyUp={handleCapital}
              onChange={handleChange}
              labelText={'Name'}
              placeholder="Name"
              touched={String(touched.name)}
            />
            <TextInput
              id="email"
              type="email"
              className="auth__input"
              value={values.email}
              invalid={!!formik.errors.email && touched.email}
              invalidText={formik.errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              labelText={'Email'}
              placeholder="Email"
              touched={String(touched.email)}
            />
            <Select
              id="position"
              defaultValue="placeholder-item"
              invalid={!!formik.errors.position && touched.position}
              invalidText={formik.errors.position}
              onBlur={handleBlur}
              onChange={handleChange}
              touched={String(touched.email)}
            >
              <SelectItem
                disabled
                hidden
                value="placeholder-item"
                text="Select position"
              />
              {selectItems}
            </Select>
          </div>
          <div className="l-btn-wrap">
            {loading.status && (
              <InlineLoading
                status={loading.status}
                onSuccess={onSuccess}
                successDelay={1500}
                description={loading.description}
              />
            )}
            <Button type="submit" className="bx--btn--mt-3">
              Apply
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

export default Application
