import { addMethod, mixed } from 'yup'

export const website = (string) => {
  // eslint-disable-next-line
  return /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
    string
  )
}

addMethod(mixed, 'website', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: 'Please enter valid website url.',
    test: (value) => {
      if (value) {
        return website(value)
      } else {
        return true
      }
    }
  })
})
