import { combineReducers } from 'redux'
import modal from './modal/reducers'
import auth from './auth/reducers'
import user from './user/reducers'
import header from './header/reducers'
import projects from './projects/reducers'
import profile from './profile/reducers'
import { firebaseReducer } from 'react-redux-firebase'

export default combineReducers({
  modal,
  user,
  auth,
  header,
  projects,
  profile,
  firebase: firebaseReducer
})
