// Auth
const AUTH_REGISTER = 'AUTH_REGISTER'
const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS'
const AUTH_REGISTER_FAILURE = 'AUTH_REGISTER_FAILURE'
const AUTH_LOGIN = 'AUTH_LOGIN'
const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
const AUTH_LOGOUT = 'AUTH_LOGOUT'
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE'
const AUTH_EMAIL_VERIFY = 'AUTH_EMAIL_VERIFY'
const AUTH_EMAIL_VERIFY_SUCCESS = 'AUTH_EMAIL_VERIFY_SUCCESS'
const AUTH_EMAIL_VERIFY_FAILURE = 'AUTH_EMAIL_VERIFY_FAILURE'
const AUTH_PASSWORD_RESET_EMAIL = 'AUTH_PASSWORD_RESET_EMAIL'
const AUTH_PASSWORD_RESET_EMAIL_SUCCESS = 'AUTH_PASSWORD_RESET_EMAIL_SUCCESS'
const AUTH_PASSWORD_RESET_EMAIL_FAILURE = 'AUTH_PASSWORD_RESET_EMAIL_FAILURE'
const AUTH_PASSWORD_RESET = 'AUTH_PASSWORD_RESET'
const AUTH_PASSWORD_RESET_SUCCESS = 'AUTH_PASSWORD_RESET_SUCCESS'
const AUTH_PASSWORD_RESET_FAILURE = 'AUTH_PASSWORD_RESET_FAILURE'
const AUTH_CHANGE_EMAIL = 'AUTH_CHANGE_EMAIL'
const AUTH_CHANGE_EMAIL_SUCCESS = 'AUTH_CHANGE_EMAIL_SUCCESS'
const AUTH_CHANGE_EMAIL_FAILURE = 'AUTH_CHANGE_EMAIL_FAILURE'
const AUTH_GET_TOKEN = 'AUTH_GET_TOKEN'
const AUTH_GET_TOKEN_SUCCESS = 'AUTH_GET_TOKEN_SUCCESS'
const AUTH_GET_TOKEN_FAILURE = 'AUTH_GET_TOKEN_FAILURE'
const AUTH_INFO_CLEAR = 'AUTH_INFO_CLEAR'
const AUTH_START_LOADING = 'AUTH_START_LOADING'
const AUTH_STOP_LOADING = 'AUTH_STOP_LOADING'
const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD'
const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS'
const AUTH_CHANGE_PASSWORD_FAILURE = 'AUTH_CHANGE_PASSWORD_FAILURE'
const AUTH_SET_REGISTER = 'AUTH_SET_REGISTER'
const AUTH_CLEAR_REGISTER = 'AUTH_CLEAR_REGISTER'
// User
const USER_FETCH = 'USER_FETCH'
const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'
const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE'
const USER_CLEAR = 'USER_CLEAR'
const USER_CREATE = 'USER_CREATE'
const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE'
const USER_UPDATE = 'USER_UPDATE'
const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'
const USER_SET_ERROR = 'USER_SET_ERROR'
const USER_INFO_CLEAR = 'USER_INFO_CLEAR'
const USER_START_LOADING = 'USER_START_LOADING'
const USER_STOP_LOADING = 'USER_STOP_LOADING'
const USER_UPLOAD_PHOTO = 'USER_UPLOAD_PHOTO'
const USER_UPLOAD_PHOTO_SUCCESS = 'USER_UPLOAD_PHOTO_SUCCESS'
const USER_UPLOAD_PHOTO_FAILURE = 'USER_UPLOAD_PHOTO_FAILURE'
// Header
const HEADER_SHOW_CHANGE_PASSWORD = 'HEADER_SHOW_CHANGE_PASSWORD'
const HEADER_CHANGE_PASSWORD = 'HEADER_CHANGE_PASSWORD'
const HEADER_CLEAR_ACTION = 'HEADER_CLEAR_ACTION'
const HEADER_TOGGLE_MENU = 'HEADER_TOGGLE_MENU'
const HEADER_PROJECT = 'HEADER_PROJECT'
const HEADER_MENU_OPENED = 'HEADER_MENU_OPENED'
const HEADER_DEFAULT = 'HEADER_DEFAULT'
const HEADER_GO_BACK = 'HEADER_GO_BACK'
// Modal
const MODAL_SHOW = 'MODAL_SHOW'
const MODAL_HIDE = 'MODAL_HIDE'
// Projects
const PROJECTS_FETCH_ALL = 'PROJECTS_FETCH_ALL'
const PROJECTS_FETCH_ALL_SUCCESS = 'PROJECTS_FETCH_ALL_SUCCESS'
const PROJECTS_FETCH_ALL_FAILURE = 'PROJECTS_FETCH_ALL_FAILURE'
const PROJECTS_FETCH_MY = 'PROJECTS_FETCH_MY'
const PROJECTS_FETCH_MY_SUCCESS = 'PROJECTS_FETCH_MY_SUCCESS'
const PROJECTS_FETCH_MY_FAILURE = 'PROJECTS_FETCH_MY_FAILURE'
const PROJECTS_FETCH_DETAIL = 'PROJECTS_FETCH_DETAIL'
const PROJECTS_FETCH_DETAIL_SUCCESS = 'PROJECTS_FETCH_DETAIL_SUCCESS'
const PROJECTS_FETCH_DETAIL_FAILURE = 'PROJECTS_FETCH_DETAIL_FAILURE'
const PROJECTS_CLEAR = 'PROJECTS_CLEAR'
const PROJECTS_CLEAR_INFO = 'PROJECTS_CLEAR_INFO'
const PROJECTS_CLEAR_SELECTED = 'PROJECTS_CLEAR_SELECTED'
const PROJECTS_START_LOADING = 'PROJECTS_START_LOADING'
const PROJECTS_STOP_LOADING = 'PROJECTS_STOP_LOADING'
const PROJECTS_SEND_APPLICATION = 'PROJECTS_SEND_APPLICATION'
const PROJECTS_SEND_APPLICATION_SUCCESS = 'PROJECTS_SEND_APPLICATION_SUCCESS'
const PROJECTS_SEND_APPLICATION_FAILURE = 'PROJECTS_SEND_APPLICATION_FAILURE'
const PROJECTS_PATCH_VISITED = 'PROJECTS_PATCH_VISITED'
const PROJECTS_PATCH_VISITED_SUCCESS = 'PROJECTS_PATCH_VISITED_SUCCESS'
const PROJECTS_ADD_ASSET = 'PROJECTS_ADD_ASSET'
const PROJECTS_ADD_ASSET_SUCCESS = 'PROJECTS_ADD_ASSET_SUCCESS'
const PROJECTS_ADD_ASSET_FAILURE = 'PROJECTS_ADD_ASSET_FAILURE'
const PROJECTS_EDIT_ASSET = 'PROJECTS_EDIT_ASSET'
const PROJECTS_EDIT_ASSET_SUCCESS = 'PROJECTS_EDIT_ASSET_SUCCESS'
const PROJECTS_EDIT_ASSET_FAILURE = 'PROJECTS_EDIT_ASSET_FAILURE'
const PROJECTS_DELETE_ASSET = 'PROJECTS_DELETE_ASSET'
const PROJECTS_DELETE_ASSET_SUCCESS = 'PROJECTS_DELETE_ASSET_SUCCESS'
const PROJECTS_DELETE_ASSET_FAILURE = 'PROJECTS_DELETE_ASSET_FAILURE'
// PROFILE
const SHOW_PROFILE_AFTER_LOGIN = 'SHOW_PROFILE_AFTER_LOGIN'

export default {
  AUTH_REGISTER,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILURE,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_EMAIL_VERIFY,
  AUTH_EMAIL_VERIFY_SUCCESS,
  AUTH_EMAIL_VERIFY_FAILURE,
  AUTH_PASSWORD_RESET_EMAIL,
  AUTH_PASSWORD_RESET_EMAIL_SUCCESS,
  AUTH_PASSWORD_RESET_EMAIL_FAILURE,
  AUTH_PASSWORD_RESET,
  AUTH_PASSWORD_RESET_SUCCESS,
  AUTH_PASSWORD_RESET_FAILURE,
  AUTH_CHANGE_EMAIL,
  AUTH_CHANGE_EMAIL_SUCCESS,
  AUTH_CHANGE_EMAIL_FAILURE,
  AUTH_GET_TOKEN,
  AUTH_GET_TOKEN_SUCCESS,
  AUTH_GET_TOKEN_FAILURE,
  AUTH_INFO_CLEAR,
  AUTH_START_LOADING,
  AUTH_STOP_LOADING,
  AUTH_CHANGE_PASSWORD,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILURE,
  AUTH_SET_REGISTER,
  AUTH_CLEAR_REGISTER,
  USER_FETCH,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_CLEAR,
  USER_CREATE,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_SET_ERROR,
  USER_INFO_CLEAR,
  USER_START_LOADING,
  USER_STOP_LOADING,
  USER_UPLOAD_PHOTO,
  USER_UPLOAD_PHOTO_SUCCESS,
  USER_UPLOAD_PHOTO_FAILURE,
  HEADER_SHOW_CHANGE_PASSWORD,
  HEADER_CHANGE_PASSWORD,
  HEADER_CLEAR_ACTION,
  HEADER_TOGGLE_MENU,
  HEADER_PROJECT,
  HEADER_MENU_OPENED,
  HEADER_DEFAULT,
  HEADER_GO_BACK,
  MODAL_SHOW,
  MODAL_HIDE,
  PROJECTS_FETCH_ALL,
  PROJECTS_FETCH_ALL_SUCCESS,
  PROJECTS_FETCH_ALL_FAILURE,
  PROJECTS_FETCH_MY,
  PROJECTS_FETCH_MY_SUCCESS,
  PROJECTS_FETCH_MY_FAILURE,
  PROJECTS_FETCH_DETAIL,
  PROJECTS_FETCH_DETAIL_SUCCESS,
  PROJECTS_FETCH_DETAIL_FAILURE,
  PROJECTS_CLEAR,
  PROJECTS_CLEAR_INFO,
  PROJECTS_CLEAR_SELECTED,
  PROJECTS_START_LOADING,
  PROJECTS_STOP_LOADING,
  PROJECTS_SEND_APPLICATION,
  PROJECTS_SEND_APPLICATION_SUCCESS,
  PROJECTS_SEND_APPLICATION_FAILURE,
  PROJECTS_PATCH_VISITED,
  PROJECTS_PATCH_VISITED_SUCCESS,
  PROJECTS_ADD_ASSET,
  PROJECTS_ADD_ASSET_SUCCESS,
  PROJECTS_ADD_ASSET_FAILURE,
  PROJECTS_EDIT_ASSET,
  PROJECTS_EDIT_ASSET_SUCCESS,
  PROJECTS_EDIT_ASSET_FAILURE,
  PROJECTS_DELETE_ASSET,
  PROJECTS_DELETE_ASSET_SUCCESS,
  PROJECTS_DELETE_ASSET_FAILURE,
  SHOW_PROFILE_AFTER_LOGIN
}
