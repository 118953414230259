import React, { useEffect } from 'react'
import { Button } from 'carbon-components-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Asset from '../Asset'
import TeamMember from '../TeamMember'
import { actions } from '../../../constants'
import { headerClearAction } from '../../../store/header/actions'
import { setShowModal } from '../../../store/modal/actions'
import ManageAssets from './ManageAssets'

const Assigned = ({ project }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [header, modal] = useSelector(({ header, modal }) => [header, modal])

  useEffect(() => {
    if (header.action === actions.HEADER_GO_BACK) {
      history.goBack()
      dispatch(headerClearAction())
    }
  }, [header, dispatch, history])

  const assets = project.assets.map((asset) => (
    <li key={asset.uuid} className="l-list__item">
      <Asset asset={asset} />
    </li>
  ))

  const assetsList = (
    <div className="project-detail__assets">
      <h2 className="c-title">Assets</h2>
      <ul className="l-list l-list--flex-lg">{assets}</ul>
      <Button
        kind="secondary"
        onClick={() => dispatch(setShowModal('addAsset'))}
      >
        + Add Asset
      </Button>
      <Button
        kind="secondary"
        onClick={() => dispatch(setShowModal('editAssets'))}
      >
        Edit Assets
      </Button>
    </div>
  )

  const creators = project.creators.map((creator) => (
    <li key={creator.email} className="l-list__item">
      <TeamMember member={creator} />
    </li>
  ))

  const emails =
    project.creators.map((creator) => creator.email).join(',') +
    ',' +
    project.projectManager.email

  const team = (
    <div className="project-detail__team">
      <h2 className="c-title">Team</h2>
      <div className="project-detail__team-block">
        <p className="c-label">Project Manager</p>
        <TeamMember member={project.projectManager} />
      </div>
      <div className="project-detail__team-block">
        <p className="c-label">Creators</p>
        <ul className="l-list">{creators}</ul>
        <Button
          href={`mailto:${emails}?subject=Lightbox - ${project.name}`}
          kind="secondary"
        >
          Contact All
        </Button>
      </div>
    </div>
  )

  return (
    <div className="container container--flex-md">
      {assetsList}
      {team}
      {modal.opened && <ManageAssets />}
    </div>
  )
}

export default Assigned
