import { actions } from '../../constants'

const defaultState = {
  type: null,
  opened: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.MODAL_SHOW:
      return { ...state, ...action.payload }
    case actions.MODAL_HIDE:
      return { ...state, opened: false }
    default:
      return state
  }
}
