import React, { useEffect, useRef, useState } from 'react'
import { setHideModal } from '../../../../store/modal/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Form,
  InlineLoading,
  Modal,
  TextInput
} from 'carbon-components-react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  projectsAddAsset,
  projectsClearInfo,
  projectsDeleteAsset,
  projectsEditAsset
} from '../../../../store/projects/actions'
import '../../../../validations'
import { capitalize } from '../../../../utils/general'

import './ManageAssets.scss'
import { actions } from '../../../../constants'

const ManageAssets = () => {
  const dispatch = useDispatch()
  const [modal, projects] = useSelector(({ modal, projects }) => [
    modal,
    projects
  ])
  const [loading, setLoading] = useState({ status: '', description: '' })
  const [selectedAsset, setSelectedAsset] = useState(null)
  const isEdit = modal.type === 'editAssets'
  let timeout = useRef(null)

  const formik = useFormik({
    initialValues: {
      name: '',
      url: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('Field is required.'),
      url: Yup.string().asset().required('This field is required.')
    }),
    onSubmit: (values) => onFormSubmit(values)
  })

  const {
    values,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    submitCount
  } = formik

  useEffect(() => {
    if (selectedAsset) {
      setFieldValue('name', selectedAsset.name)
      setFieldValue('url', selectedAsset.url)
    }
  }, [selectedAsset, setFieldValue])

  useEffect(() => {
    if (submitCount) {
      if (projects.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      }
      if (
        projects.response?.type.match(
          /PROJECTS_ADD_ASSET_SUCCESS|PROJECTS_EDIT_ASSET_SUCCESS/
        )
      ) {
        setLoading({
          status: 'finished',
          description: isEdit ? 'Asset edited.' : 'Asset added.'
        })
        dispatch(projectsClearInfo())
      } else if (projects.error) {
        setLoading({ status: 'error', description: projects.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(projectsClearInfo())
        }, 3000)
      }
    }
    if (projects.response?.type === actions.PROJECTS_DELETE_ASSET_SUCCESS) {
      dispatch(projectsClearInfo())
      dispatch(setHideModal())
    }
  }, [projects, dispatch, submitCount, isEdit])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const handleCapital = (e) => {
    e.preventDefault()
    formik.setFieldValue(e.target.id, capitalize(e.target.value))
  }

  const onFormSubmit = (values) => {
    const data = {
      projectUuid: projects.selected.uuid,
      asset: {
        name: values.name,
        url: values.url
      }
    }
    if (isEdit) {
      data.assetUuid = selectedAsset.uuid
      dispatch(projectsEditAsset(data))
    } else {
      dispatch(projectsAddAsset(data))
    }
  }

  const onSuccess = () => {
    setLoading({ status: '', description: '' })
    dispatch(projectsClearInfo())
    dispatch(setHideModal())
  }

  const assetForm = (
    <div className="auth">
      <Form className="auth__form" onSubmit={handleSubmit}>
        <div className="l-form-wrap">
          <TextInput
            id="name"
            className="auth__input"
            value={values.name}
            invalid={!!formik.errors.name && touched.name}
            invalidText={formik.errors.name}
            onBlur={handleBlur}
            onKeyUp={handleCapital}
            onChange={handleChange}
            labelText={'Name'}
            placeholder="Name"
            touched={String(touched.name)}
          />
          <TextInput
            id="url"
            className="auth__input"
            value={values.url}
            invalid={!!formik.errors.url && touched.url}
            invalidText={formik.errors.url}
            onBlur={handleBlur}
            onChange={handleChange}
            labelText={'Url'}
            placeholder="Url"
            touched={String(touched.url)}
          />
        </div>
        <div className="l-btn-wrap">
          {loading.status && (
            <InlineLoading
              status={loading.status}
              onSuccess={onSuccess}
              successDelay={1500}
              description={loading.description}
            />
          )}
          <Button type="submit" className="bx--btn--mt-3">
            Save
          </Button>
          <Button
            type="button"
            kind="secondary"
            onClick={() => dispatch(setHideModal())}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )

  const assetItems = projects.selected.assets.map((asset) => (
    <li
      key={asset.uuid}
      className="l-list__item"
      onClick={() => setSelectedAsset(asset)}
    >
      <p className="c-asset">{asset.name}</p>
    </li>
  ))

  const assetsList = (
    <>
      <ul className="l-list l-list--flex-lg">{assetItems}</ul>
      <div className="l-btn-wrap">
        <Button
          type="button"
          kind="secondary"
          onClick={() => dispatch(setHideModal())}
        >
          Cancel
        </Button>
      </div>
    </>
  )

  const modalHeading = (
    <div className="c-manage-assets__heading">
      <div>
        <h3 className="c-title">
          {isEdit
            ? selectedAsset
              ? 'Edit Asset'
              : 'Edit Assets'
            : 'Add Asset'}
        </h3>
        {isEdit && !selectedAsset && (
          <h3 className="c-text">Select asset to edit.</h3>
        )}
      </div>
      {selectedAsset && (
        <Button
          type="button"
          kind="ghost"
          onClick={() => {
            dispatch(
              projectsDeleteAsset({
                projectUuid: projects.selected.uuid,
                assetUuid: selectedAsset.uuid
              })
            )
          }}
        >
          Delete
        </Button>
      )}
    </div>
  )

  return (
    <Modal
      id="input-modal"
      modalHeading={modalHeading}
      open={modal.opened}
      passiveModal
      className="c-manage-assets"
      onRequestClose={() => dispatch(setHideModal())}
    >
      {selectedAsset || !isEdit ? assetForm : assetsList}
    </Modal>
  )
}

export default ManageAssets
