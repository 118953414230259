import { actions } from '../../constants'

const userFetch = () => ({ type: actions.USER_FETCH })
const userFetchSuccess = (data) => ({
  type: actions.USER_FETCH_SUCCESS,
  payload: data
})
const userFetchFailure = (err) => ({
  type: actions.USER_FETCH_FAILURE,
  payload: err
})
const userClear = () => ({ type: actions.USER_CLEAR })

const userCreate = (data) => ({ type: actions.USER_CREATE, payload: data })
const userCreateSuccess = (data) => ({
  type: actions.USER_CREATE_SUCCESS,
  payload: data
})
const userCreateFailure = (err) => ({
  type: actions.USER_CREATE_FAILURE,
  payload: err
})

const userUpdate = (data) => ({ type: actions.USER_UPDATE, payload: data })
const userUpdateSuccess = (data) => ({
  type: actions.USER_UPDATE_SUCCESS,
  payload: data
})
const userUpdateFailure = (err) => ({
  type: actions.USER_UPDATE_FAILURE,
  payload: err
})

const userSetError = (err) => ({ type: actions.USER_SET_ERROR, payload: err })

const userStartLoading = () => ({ type: actions.USER_START_LOADING })
const userStopLoading = () => ({ type: actions.USER_STOP_LOADING })

const userUploadPhoto = () => ({ type: actions.USER_UPLOAD_PHOTO })
const userUploadPhotoSuccess = () => ({
  type: actions.USER_UPLOAD_PHOTO_SUCCESS
})
const userUploadPhotoFailure = () => ({
  type: actions.USER_UPLOAD_PHOTO_FAILURE
})

const userInfoClear = () => ({ type: actions.USER_INFO_CLEAR })

export {
  userFetch,
  userFetchSuccess,
  userFetchFailure,
  userClear,
  userCreate,
  userCreateSuccess,
  userCreateFailure,
  userUpdate,
  userUpdateSuccess,
  userUpdateFailure,
  userSetError,
  userStartLoading,
  userStopLoading,
  userInfoClear,
  userUploadPhoto,
  userUploadPhotoSuccess,
  userUploadPhotoFailure
}
