import { actions } from '../../constants'

const headerShowChangePassword = () => ({
  type: actions.HEADER_SHOW_CHANGE_PASSWORD
})
const headerChangePassword = () => ({ type: actions.HEADER_CHANGE_PASSWORD })
const headerClearAction = () => ({ type: actions.HEADER_CLEAR_ACTION })
const headerToggleMenu = () => ({ type: actions.HEADER_TOGGLE_MENU })
const headerProject = () => ({ type: actions.HEADER_PROJECT })
const headerDefault = () => ({ type: actions.HEADER_DEFAULT })
const headerMenuOpened = () => ({ type: actions.HEADER_MENU_OPENED })
const headerGoBack = () => ({ type: actions.HEADER_GO_BACK })

export {
  headerShowChangePassword,
  headerChangePassword,
  headerClearAction,
  headerToggleMenu,
  headerProject,
  headerMenuOpened,
  headerDefault,
  headerGoBack
}
