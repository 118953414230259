import React, { useEffect, useState } from 'react'
import { Button } from 'carbon-components-react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Logo from '../Logo'
import { setShowModal } from '../../store/modal/actions'
import {
  headerDefault,
  headerChangePassword,
  headerToggleMenu,
  headerProject,
  headerMenuOpened,
  headerGoBack
} from '../../store/header/actions'
import useLockBodyScroll from 'hooks/useLockBodyScroll'

import './Header.scss'

const Header = () => {
  const dispatch = useDispatch()
  const [unlockBodyScroll] = useLockBodyScroll()
  const history = useHistory()
  const fbAuth = useSelector(({ firebase }) => firebase.auth)
  const loggedIn = fbAuth.emailVerified && !fbAuth.isEmpty

  const [saveBtn, setShowSaveBtn] = useState(false)
  const [backBtn, setBackBtn] = useState(false)

  const [
    headerBackBtn,
    headerSaveBtn,
    menuOpened,
    project
  ] = useSelector(({ header, projects }) => [
    header.backBtn,
    header.saveBtn,
    header.menuOpened,
    projects.selected
  ])

  let isHome = history.location.pathname === '/'
  const toggleClassList = `header__burger menu-toggle ${
    backBtn ? 'menu-toggle--arrow' : ''
  } ${menuOpened ? 'js-opened' : ''} ${isHome ? '' : 'menu-toggle--simple'}`
  const menuClassList = `header__nav ${menuOpened ? 'js-opened' : ''}`
  const overlayClassList = `menu-overlay ${menuOpened ? 'js-opened' : ''}`
  const headerClassList = `header ${isHome ? 'header--home' : ''} ${
    project ? 'header--project' : ''
  }`

  useEffect(() => {
    if (!menuOpened) {
      unlockBodyScroll()
    } else {
      document.body.style.overflow = 'hidden'
    }
  }, [menuOpened, unlockBodyScroll])

  useEffect(() => {
    dispatch(headerDefault())
  }, [history.location.pathname, dispatch])

  useEffect(() => {
    if (project) {
      dispatch(headerProject())
    }
  }, [project, dispatch])

  useEffect(() => {
    setShowSaveBtn(headerSaveBtn)
    setBackBtn(headerBackBtn)
  }, [headerBackBtn, headerSaveBtn])

  const toggleMenu = () => {
    if (backBtn) {
      if (headerSaveBtn) {
        return dispatch(headerMenuOpened())
      }
      if (project) {
        history.push('/projects')
      }
      dispatch(headerGoBack())
      return
    }
    dispatch(headerToggleMenu())
  }

  const authMenu = (
    <div className={menuClassList}>
      <Button onClick={() => dispatch(setShowModal('register'))}>Join</Button>
      <Button
        className="bx--btn--white"
        kind="secondary"
        onClick={() => dispatch(setShowModal('logIn'))}
      >
        Sign In
      </Button>
    </div>
  )

  const saveBtnEl = (
    <>
      <Button
        className="header__btn"
        kind="ghost"
        onClick={() => dispatch(headerChangePassword())}
      >
        Save
      </Button>
    </>
  )

  const projectsBtn = (
    <Button onClick={() => history.push('/projects')}>View Projects</Button>
  )

  return (
    <header
      className={headerClassList}
      style={project && { backgroundImage: `url(${project.bannerPhoto})` }}
    >
      <div className="container header__container">
        <div className="header__logo-wrap">
          <Link to={'/'}>
            <Logo light={project} short={!isHome} />
          </Link>
        </div>

        <div className="header__menu-wrap">
          <div className={toggleClassList} onClick={toggleMenu}>
            <span className="menu-toggle__bar menu-toggle__bar--1" />
            <span className="menu-toggle__bar menu-toggle__bar--2" />
            <span className="menu-toggle__bar menu-toggle__bar--3" />
          </div>
        </div>

        {isHome && loggedIn ? projectsBtn : authMenu}

        {isHome && <div className={overlayClassList} />}

        <div className="header__btn-wrap">
          {!isHome && saveBtn && saveBtnEl}
        </div>
      </div>
    </header>
  )
}

export default Header
