import React from 'react'

import './SocialLink.scss'

const SocialLink = ({ link, icon }) => {
  return (
    <a
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      className="social-link"
    >
      {icon}
    </a>
  )
}

export default SocialLink
