import { actions } from '../../constants'

const defaultState = {
  all: null,
  my: null,
  selected: null,
  loading: false,
  response: null,
  error: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.PROJECTS_FETCH_ALL_SUCCESS:
      return { ...state, all: action.payload }
    case actions.PROJECTS_FETCH_MY_SUCCESS:
      return { ...state, my: action.payload }
    case actions.PROJECTS_FETCH_DETAIL_SUCCESS:
      return { ...state, selected: action.payload }
    case actions.PROJECTS_SEND_APPLICATION_SUCCESS:
      return {
        ...state,
        response: {
          type: actions.PROJECTS_SEND_APPLICATION_SUCCESS,
          response: action.payload
        }
      }
    case actions.PROJECTS_PATCH_VISITED_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const projectsCopy = state.my.map((project) => {
        if (project.uuid === action.payload) {
          project.status = 'in_progress'
        }
        return project
      })
      return {
        ...state,
        my: projectsCopy
      }
    case actions.PROJECTS_ADD_ASSET_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          assets: [...state.selected.assets, action.payload]
        },
        response: {
          type: actions.PROJECTS_ADD_ASSET_SUCCESS,
          response: action.payload
        }
      }
    case actions.PROJECTS_EDIT_ASSET_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newAssets = state.selected.assets.map((asset) => {
        if (asset.uuid === action.payload.uuid) {
          asset.name = action.payload.asset.name
          asset.url = action.payload.asset.url
        }
        return asset
      })
      return {
        ...state,
        selected: { ...state.selected, assets: [...newAssets] },
        response: {
          type: actions.PROJECTS_EDIT_ASSET_SUCCESS,
          response: action.payload
        }
      }
    case actions.PROJECTS_DELETE_ASSET_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const filteredAssets = state.selected.assets.filter(
        (asset) => asset.uuid !== action.payload
      )
      return {
        ...state,
        selected: { ...state.selected, assets: [...filteredAssets] },
        response: {
          type: actions.PROJECTS_DELETE_ASSET_SUCCESS,
          response: action.payload
        }
      }
    case actions.PROJECTS_FETCH_ALL_FAILURE:
    case actions.PROJECTS_FETCH_MY_FAILURE:
    case actions.PROJECTS_FETCH_DETAIL_FAILURE:
    case actions.PROJECTS_SEND_APPLICATION_FAILURE:
    case actions.PROJECTS_ADD_ASSET_FAILURE:
    case actions.PROJECTS_EDIT_ASSET_FAILURE:
      return { ...state, error: { type: action.type, data: action.payload } }
    case actions.PROJECTS_CLEAR_SELECTED:
      return { ...state, selected: null }
    case actions.PROJECTS_CLEAR_INFO:
      return { ...state, response: null, error: null }
    case actions.PROJECTS_START_LOADING:
      return { ...state, loading: true }
    case actions.PROJECTS_STOP_LOADING:
      return { ...state, loading: false }
    case actions.PROJECTS_CLEAR:
      return defaultState
    default:
      return state
  }
}
