import React from 'react'
import {
  AccordionSkeleton,
  ButtonSkeleton,
  SkeletonPlaceholder,
  SkeletonText
} from 'carbon-components-react'
import TeamMemberSkeleton from './TeamMember/TeamMemberSkeleton'

const ProjectDetailSkeleton = ({ isMyProject }) => {
  const myProject = (
    <div className="container container--flex-md">
      <div className="project-detail__assets">
        <SkeletonText className="c-title" width={'100px'} />
        <ul className="l-list l-list--flex-lg">
          <li className="l-list__item">
            <SkeletonPlaceholder style={{ width: '100%', height: '48px' }} />
          </li>
          <li className="l-list__item">
            <SkeletonPlaceholder style={{ width: '100%', height: '48px' }} />
          </li>
          <li className="l-list__item">
            <SkeletonPlaceholder style={{ width: '100%', height: '48px' }} />
          </li>
          <li className="l-list__item">
            <SkeletonPlaceholder style={{ width: '100%', height: '48px' }} />
          </li>
          <li className="l-list__item">
            <SkeletonPlaceholder style={{ width: '100%', height: '48px' }} />
          </li>
        </ul>
        <ButtonSkeleton />
        <ButtonSkeleton />
      </div>
      <div className="project-detail__team">
        <SkeletonText className="c-title" width={'100px'} />
        <div className="project-detail__team-block">
          <SkeletonText className="c-label" width={'150px'} />
          <TeamMemberSkeleton />
        </div>
        <div className="project-detail__team-block">
          <SkeletonText className="c-label" width={'150px'} />
          <ul className="l-list">
            <li className="l-list__item">
              <TeamMemberSkeleton />
            </li>
            <li className="l-list__item">
              <TeamMemberSkeleton />
            </li>
            <li className="l-list__item">
              <TeamMemberSkeleton />
            </li>
          </ul>
          <ButtonSkeleton />
        </div>
      </div>
    </div>
  )

  const allProject = (
    <div className="container container--flex-md">
      <div className="project-detail__brief">
        <SkeletonText className="c-label" width={'150px'} />
        <SkeletonText className="c-text" paragraph lineCount={5} />
        <ButtonSkeleton className="bx--btn--hide-md" />
        <TeamMemberSkeleton className="member--hide member--show-md" />
      </div>
      <div className="project-detail__positions">
        <SkeletonText className="c-label" width={'150px'} />
        <AccordionSkeleton open={false} count={4} />
        <TeamMemberSkeleton className="member--hide-md" />
      </div>
    </div>
  )

  return (
    <div className="project-detail">
      <div className="c-hero">
        <div className="container">
          <div className="c-hero__info">
            <SkeletonText className="c-title" width={'300px'} />
            <SkeletonText className="c-text" paragraph lineCount={2} />
          </div>
          {!isMyProject && (
            <ButtonSkeleton className="bx--btn--white bx--btn--hide bx--btn--show-md" />
          )}
        </div>
      </div>
      {isMyProject ? myProject : allProject}
    </div>
  )
}

export default ProjectDetailSkeleton
