import { actions } from '../../constants'

export const setShowModal = (type) => ({
  type: actions.MODAL_SHOW,
  payload: { opened: true, type }
})

export const setHideModal = () => ({
  type: actions.MODAL_HIDE
})
