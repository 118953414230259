import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Form, InlineLoading, TextInput } from 'carbon-components-react'
import { authInfoClear, authPasswordReset } from 'store/auth/actions'
import { setHideModal } from 'store/modal/actions'
import actions from 'constants/actions'
import { sha256 } from 'utils/general'
import './ChangePassword.scss'

const ChangePassword = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const auth = useSelector(({ auth }) => auth)

  const [loading, setLoading] = useState({ status: '', description: '' })
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordRepeat: ''
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .min(6, 'This field should contain at least 6 characters.')
        .required('This field is required.'),
      newPasswordRepeat: Yup.string()
        .required('This field is required.')
        .oneOf([Yup.ref('newPassword')], 'New password fields should match.')
    }),
    onSubmit: (values) => onFormSubmit(values)
  })

  const { values, handleChange, touched, handleBlur, handleSubmit } = formik

  let timeout = useRef(null)

  useEffect(() => {
    if (formik.submitCount) {
      if (auth.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      }
      if (auth.response?.type === actions.AUTH_PASSWORD_RESET_SUCCESS) {
        setLoading({ status: '', description: '' })
        setSuccess(true)
      } else if (auth.error) {
        setLoading({ status: 'error', description: auth.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(authInfoClear())
        }, 3000)
      }
    }
  }, [auth, dispatch, formik.submitCount])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const onFormSubmit = (values) => {
    const oobCode = urlParams.get('oobCode')
    dispatch(
      authPasswordReset({ oobCode, password: sha256(values.newPassword) })
    )
  }

  const fieldsList = [
    {
      id: 'newPassword',
      className: 'auth__input',
      type: 'password',
      value: values.newPassword,
      invalid: !!formik.errors.newPassword && touched.newPassword,
      invalidText: formik.errors.newPassword,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'New password',
      placeholder: 'Password',
      touched: String(touched.newPassword)
    },
    {
      id: 'newPasswordRepeat',
      className: 'auth__input',
      type: 'password',
      value: values.newPasswordRepeat,
      invalid: !!formik.errors.newPasswordRepeat && touched.newPasswordRepeat,
      invalidText: formik.errors.newPasswordRepeat,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Confirm New Password',
      placeholder: 'Password',
      touched: String(touched.newPasswordRepeat)
    }
  ]

  const fields = fieldsList.map((field, key) => {
    return <TextInput key={key} {...field} />
  })

  const resetForm = (
    <>
      <h3 className="auth__title auth__title--standalone">
        Enter new password.
      </h3>
      <Form className="auth__form" onSubmit={handleSubmit}>
        <div className="l-form-wrap">{fields}</div>
        <div className="l-btn-wrap">
          {loading.status && (
            <InlineLoading
              status={loading.status}
              description={loading.description}
            />
          )}
          <Button type="submit">Change</Button>
        </div>
      </Form>
    </>
  )

  const handleOkClick = () => {
    const continueUrl = urlParams.get('continueUrl')

    if (continueUrl) {
      window.open(continueUrl, 'Lightbox Admin').focus()
    }

    dispatch(setHideModal())
  }

  const successItem = (
    <>
      <p>Your password was changed.</p>
      <div className="l-btn-wrap">
        <Button type="button" onClick={handleOkClick}>
          Ok
        </Button>
      </div>
    </>
  )

  return success ? successItem : resetForm
}

export default ChangePassword
