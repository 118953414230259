import React from 'react'
import { SkeletonText } from 'carbon-components-react'

const ProjectsSkeleton = () => {
  return (
    <div
      className="c-project"
      style={{ backgroundColor: 'rgba(168, 168, 168, 0.1)' }}
    >
      <div className="c-project__name" style={{ width: '100%' }}>
        <SkeletonText paragraph lineCount={2} />
      </div>
    </div>
  )
}

export default ProjectsSkeleton
