import { takeEvery, put, call, all } from 'redux-saga/effects'

import { actions } from '../constants'
import FirebaseService from '../services/firebase-service'
import {
  authChangeEmailFailure,
  authChangeEmailSuccess,
  authChangePasswordFailure,
  authChangePasswordSuccess,
  authClearRegister,
  authEmailVerifyFailure,
  authEmailVerifySuccess,
  authInfoClear,
  authLoginFailure,
  authLoginSuccess,
  authLogoutFailure,
  authLogoutSuccess,
  authPasswordResetEmailFailure,
  authPasswordResetEmailSuccess,
  authPasswordResetFailure,
  authPasswordResetSuccess,
  authRegisterFailure,
  authRegisterSuccess,
  authStartLoading,
  authStopLoading
} from '../store/auth/actions'
import { userClear, userInfoClear } from '../store/user/actions'

const firebaseService = new FirebaseService()

function* registerUser(action) {
  try {
    yield put(authStartLoading())
    const response = yield call(firebaseService.register, action.payload)
    yield put(authClearRegister())
    yield put(authRegisterSuccess(response))
    yield put(authStopLoading())
  } catch (error) {
    yield put(authClearRegister())
    yield put(authRegisterFailure(error))
    yield put(authStopLoading())
  }
}

function* emailVerify(action) {
  try {
    yield put(authStartLoading())
    const response = yield call(firebaseService.emailVerify, action.payload)
    yield put(authEmailVerifySuccess(response))
    yield put(authStopLoading())
  } catch (error) {
    yield put(authEmailVerifyFailure(error))
    yield put(authStopLoading())
  }
}

function* loginUser(action) {
  try {
    yield put(authStartLoading())
    const { email, password } = action.payload
    const response = yield call(firebaseService.login, email, password)
    yield put(authLoginSuccess(response))
    yield put(authStopLoading())
  } catch (error) {
    yield put(authLoginFailure(error))
    yield put(authStopLoading())
  }
}

function* logoutUser() {
  try {
    yield put(authStartLoading())
    const response = yield call(firebaseService.logout)
    yield put(authLogoutSuccess(response))
    yield put(userClear())
    yield put(authStopLoading())
    yield put(userInfoClear())
    yield put(authInfoClear())
  } catch (error) {
    yield put(authLogoutFailure(error))
    yield put(authStopLoading())
  }
}

function* passwordResetEmail(action) {
  try {
    yield put(authStartLoading())
    const response = yield call(
      firebaseService.passwordResetEmail,
      action.payload
    )
    yield put(authPasswordResetEmailSuccess(response))
    yield put(authStopLoading())
  } catch (error) {
    yield put(authPasswordResetEmailFailure(error))
    yield put(authStopLoading())
  }
}

function* passwordReset(action) {
  try {
    yield put(authStartLoading())
    const { oobCode, password } = action.payload
    const response = yield call(
      firebaseService.passwordReset,
      oobCode,
      password
    )
    yield put(authPasswordResetSuccess(response))
    yield put(authStopLoading())
  } catch (error) {
    yield put(authPasswordResetFailure(error))
    yield put(authStopLoading())
  }
}

function* changeEmail(action) {
  try {
    yield put(authStartLoading())
    const response = yield call(firebaseService.changeEmail, action.payload)
    yield put(authChangeEmailSuccess(response))
    yield put(authStopLoading())
  } catch (error) {
    yield put(authChangeEmailFailure(error))
    yield put(authStopLoading())
  }
}

function* changePassword(action) {
  try {
    yield put(authStartLoading())
    const response = yield call(firebaseService.changePassword, action.payload)
    yield put(authChangePasswordSuccess(response))
    yield put(authStopLoading())
  } catch (error) {
    yield put(authChangePasswordFailure(error))
    yield put(authStopLoading())
  }
}

export function* watchAuth() {
  yield all([
    takeEvery(actions.AUTH_REGISTER, registerUser),
    takeEvery(actions.AUTH_EMAIL_VERIFY, emailVerify),
    takeEvery(actions.AUTH_LOGIN, loginUser),
    takeEvery(actions.AUTH_LOGOUT, logoutUser),
    takeEvery(actions.AUTH_PASSWORD_RESET_EMAIL, passwordResetEmail),
    takeEvery(actions.AUTH_PASSWORD_RESET, passwordReset),
    takeEvery(actions.AUTH_CHANGE_EMAIL, changeEmail),
    takeEvery(actions.AUTH_CHANGE_PASSWORD, changePassword)
  ])
}
