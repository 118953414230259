import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Form, InlineLoading, TextInput } from 'carbon-components-react'
import { actions } from '../../../constants'
import { authChangePassword, authInfoClear } from '../../../store/auth/actions'
import {
  headerDefault,
  headerShowChangePassword
} from '../../../store/header/actions'
import { sha256 } from 'utils/general'
import './ChangePassword.scss'

const ChangePassword = () => {
  const dispatch = useDispatch()

  const [auth, headerAction] = useSelector(({ auth, header }) => [
    auth,
    header.action
  ])

  const [loading, setLoading] = useState({ status: '', description: '' })

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordRepeat: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(6, 'This field should contain at least 6 characters.')
        .required('This field is required.'),
      newPassword: Yup.string()
        .min(6, 'This field should contain at least 6 characters.')
        .required('This field is required.'),
      newPasswordRepeat: Yup.string()
        .required('This field is required.')
        .oneOf([Yup.ref('newPassword')], 'New password fields should match.')
    }),
    onSubmit: (values) => onFormSubmit(values)
  })

  const { values, handleChange, touched, handleBlur, handleSubmit } = formik

  let timeout = useRef(null)

  useEffect(() => {
    if (headerAction === 'changePassword') {
      handleSubmit()
      dispatch(headerShowChangePassword())
    }
  }, [headerAction, dispatch, handleSubmit])

  useEffect(() => {
    if (formik.submitCount) {
      if (auth.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      }
      if (auth.response?.type === actions.AUTH_CHANGE_PASSWORD_SUCCESS) {
        setLoading({ status: 'finished', description: 'Password changed.' })
      } else if (auth.error) {
        setLoading({ status: 'error', description: auth.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(authInfoClear())
        }, 3000)
      }
    }
  }, [auth, formik.submitCount, dispatch])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const onFormSubmit = ({ password, newPassword, newPasswordRepeat }) => {
    dispatch(
      authChangePassword({
        password: sha256(password),
        newPassword: sha256(newPassword),
        newPasswordRepeat: sha256(newPasswordRepeat)
      })
    )
  }

  const onSuccess = async () => {
    await setLoading({ status: '', description: '' })
    dispatch(headerDefault())
  }

  const fieldsList = [
    {
      id: 'password',
      className: 'auth__input',
      type: 'password',
      value: values.password,
      invalid: !!formik.errors.password && touched.password,
      invalidText: formik.errors.password,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Current password',
      placeholder: 'Password',
      touched: String(touched.password)
    },
    {
      id: 'newPassword',
      className: 'auth__input',
      type: 'password',
      value: values.newPassword,
      invalid: !!formik.errors.newPassword && touched.newPassword,
      invalidText: formik.errors.newPassword,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'New password',
      placeholder: 'Password',
      touched: String(touched.newPassword)
    },
    {
      id: 'newPasswordRepeat',
      className: 'auth__input',
      type: 'password',
      value: values.newPasswordRepeat,
      invalid: !!formik.errors.newPasswordRepeat && touched.newPasswordRepeat,
      invalidText: formik.errors.newPasswordRepeat,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'New password, again',
      placeholder: 'Password',
      touched: String(touched.newPasswordRepeat)
    }
  ]

  const fields = fieldsList.map((field, key) => (
    <TextInput key={key} {...field} />
  ))

  return (
    <div className="container">
      <h2 className="c-title">Change Password</h2>
      <Form className="change-password__form" onSubmit={handleSubmit}>
        <div className="l-form-wrap">{fields}</div>
      </Form>
      {loading.status && (
        <InlineLoading
          status={loading.status}
          onSuccess={onSuccess}
          successDelay={1500}
          description={loading.description}
        />
      )}
    </div>
  )
}

export default ChangePassword
