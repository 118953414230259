import React from 'react'
import { Launch32 } from '@carbon/icons-react'

import './Asset.scss'

const Asset = ({ asset }) => {
  return (
    <a
      href={asset.url}
      target="_blank"
      rel="noopener noreferrer"
      className="c-asset"
    >
      <p className="c-asset__name">{asset.name}</p>
      <span className="c-asset__icon">
        <Launch32 />
      </span>
    </a>
  )
}

export default Asset
