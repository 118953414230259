import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Form, InlineLoading, TextInput } from 'carbon-components-react'
import { setShowModal } from '../../../store/modal/actions'
import { authInfoClear, authLogin } from '../../../store/auth/actions'
import { actions } from '../../../constants'
import { sha256 } from 'utils/general'
import './LogIn.scss'

const LogIn = () => {
  const dispatch = useDispatch()

  const auth = useSelector(({ auth }) => auth)

  const history = useHistory()

  const [loading, setLoading] = useState({ status: '', description: '' })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Please enter valid email address.')
        .required('This field is required.'),
      password: Yup.string()
        .min(6, 'This field should contain at least 6 characters.')
        .required('This field is required.')
    }),
    onSubmit: (values) => onFormSubmit(values)
  })

  const { values, handleChange, touched, handleBlur, handleSubmit } = formik

  let timeout = useRef(null)

  useEffect(() => {
    if (formik.submitCount) {
      if (auth.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      }
      if (auth.response?.type === actions.AUTH_LOGIN_SUCCESS) {
        setLoading({
          status: 'finished',
          description: 'You are logged in.'
        })
      } else if (auth.error) {
        setLoading({ status: 'error', description: auth.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(authInfoClear())
        }, 3000)
      }
    }
  }, [auth, dispatch, formik.submitCount])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const onFormSubmit = (values) =>
    dispatch(authLogin({ ...values, password: sha256(values.password) }))

  const fieldsList = [
    {
      id: 'email',
      type: 'email',
      className: 'auth__input',
      value: values.email,
      invalid: !!formik.errors.email && touched.email && !!formik.submitCount,
      invalidText: formik.errors.email,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Email',
      placeholder: 'Email',
      touched: String(touched.email)
    },
    {
      id: 'password',
      type: 'password',
      className: 'auth__input',
      value: values.password,
      invalid:
        !!formik.errors.password && touched.password && !!formik.submitCount,
      invalidText: formik.errors.password,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Password',
      placeholder: 'Password',
      touched: String(touched.password)
    }
  ]

  const onSuccess = () => history.push('/projects')

  const fields = fieldsList.map((field, key) => (
    <TextInput key={key} {...field} />
  ))

  return (
    <>
      <h3 className="auth__title auth__title--standalone">
        Login to your Lightbox account.
      </h3>
      <Form className="auth__form" onSubmit={handleSubmit}>
        <div className="l-form-wrap">{fields}</div>
        <div>
          <span
            className="auth__link"
            onClick={() => dispatch(setShowModal('forgot'))}
          >
            Login help
          </span>
        </div>
        <div className="l-btn-wrap">
          {loading.status && (
            <InlineLoading
              status={loading.status}
              description={loading.description}
              onSuccess={onSuccess}
              successDelay={500}
            />
          )}
          <Button type="submit">Login</Button>
        </div>
      </Form>
    </>
  )
}

export default LogIn
