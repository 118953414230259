const OOPS = 'Oops... Error :('
const WEAK_PASSWORD = 'The password is not strong enough.'
const TOO_MANY_REQUESTS =
  'Access to this account has been temporarily disabled due to many failed login attempts.'
const PASSWORD_IS_INVALID = 'The password is invalid.'
const ENTER_VALID_WEBSITE_URL = 'Please enter valid website url.'
const USER_NOT_FOUND = 'There is no user record corresponding to this email.'
const INVALID_EMAIL = 'There is no user corresponding to the given email.'
const EMAIL_ALREADY_IN_USE =
  'There already exists an account with the given email address.'
const VERIFY_YOUR_EMAIL =
  'Please check your email and click the link to verify your account and complete the membership process.'
const EXPIRED_ACTION_CODE = 'Code has expired.'
const INVALID_ACTION_CODE =
  'Code is invalid. This can happen if the code is malformed or has already been used.'
const INVALID_URL = 'Please enter valid Url address.'

export default {
  OOPS,
  WEAK_PASSWORD,
  TOO_MANY_REQUESTS,
  PASSWORD_IS_INVALID,
  ENTER_VALID_WEBSITE_URL,
  USER_NOT_FOUND,
  INVALID_EMAIL,
  EMAIL_ALREADY_IN_USE,
  VERIFY_YOUR_EMAIL,
  EXPIRED_ACTION_CODE,
  INVALID_ACTION_CODE,
  INVALID_URL
}
