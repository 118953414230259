import { all, put, call, takeEvery } from 'redux-saga/effects'

import { actions } from '../constants'
import {
  projectsAddAssetFailure,
  projectsAddAssetSuccess,
  projectsDeleteAssetFailure,
  projectsDeleteAssetSuccess,
  projectsEditAssetFailure,
  projectsEditAssetSuccess,
  projectsFetchAllFailure,
  projectsFetchAllSuccess,
  projectsFetchDetailFailure,
  projectsFetchDetailSuccess,
  projectsFetchMyFailure,
  projectsFetchMySuccess,
  projectsPatchVisitedSuccess,
  projectsSendApplicationFailure,
  projectsSendApplicationSuccess,
  projectsStartLoading,
  projectsStopLoading
} from '../store/projects/actions'
import ProjectsService from '../services/projects-service'
import FirebaseService from '../services/firebase-service'

const projectsService = new ProjectsService()
const firebaseService = new FirebaseService()

function* fetchAllProjects() {
  try {
    yield put(projectsStartLoading())
    const token = yield call(firebaseService.getUserToken)
    const response = yield call(
      projectsService.getProjects,
      token,
      'open-projects'
    )
    yield put(projectsFetchAllSuccess(response))
    yield put(projectsStopLoading())
  } catch (error) {
    yield put(projectsFetchAllFailure(error))
    yield put(projectsStopLoading())
  }
}

function* fetchMyProjects() {
  try {
    yield put(projectsStartLoading())
    const token = yield call(firebaseService.getUserToken)
    const response = yield call(
      projectsService.getProjects,
      token,
      'my-projects'
    )
    yield put(projectsFetchMySuccess(response))
    yield put(projectsStopLoading())
  } catch (error) {
    yield put(projectsFetchMyFailure(error))
    yield put(projectsStopLoading())
  }
}

function* fetchProjectsDetail(action) {
  try {
    yield put(projectsStartLoading())
    const token = yield call(firebaseService.getUserToken)
    const response = yield call(
      projectsService.getProject,
      token,
      action.payload
    )
    yield put(projectsFetchDetailSuccess(response))
    yield put(projectsStopLoading())
  } catch (error) {
    yield put(projectsFetchDetailFailure(error))
    yield put(projectsStopLoading())
  }
}

function* sendApplication(action) {
  try {
    yield put(projectsStartLoading())
    const token = yield call(firebaseService.getUserToken)
    const response = yield call(
      projectsService.sendApplication,
      token,
      action.payload
    )
    yield put(projectsSendApplicationSuccess(response))
    yield put(projectsStopLoading())
  } catch (error) {
    yield put(projectsSendApplicationFailure(error))
    yield put(projectsStopLoading())
  }
}

function* patchVisited(action) {
  try {
    const token = yield call(firebaseService.getUserToken)
    yield call(projectsService.patchVisited, token, action.payload)
    yield put(projectsPatchVisitedSuccess(action.payload))
  } catch (error) {
    console.log(error)
  }
}

function* addAsset(action) {
  try {
    yield put(projectsStartLoading())
    const token = yield call(firebaseService.getUserToken)
    const response = yield call(projectsService.addAsset, token, action.payload)
    yield put(projectsAddAssetSuccess(response))
    yield put(projectsStopLoading())
  } catch (error) {
    yield put(projectsAddAssetFailure(error))
    yield put(projectsStopLoading())
  }
}

function* editAsset(action) {
  try {
    yield put(projectsStartLoading())
    const token = yield call(firebaseService.getUserToken)
    const response = yield call(
      projectsService.editAsset,
      token,
      action.payload
    )
    yield put(projectsEditAssetSuccess(response))
    yield put(projectsStopLoading())
  } catch (error) {
    yield put(projectsEditAssetFailure(error))
    yield put(projectsStopLoading())
  }
}

function* deleteAsset(action) {
  try {
    yield put(projectsStartLoading())
    const token = yield call(firebaseService.getUserToken)
    const response = yield call(
      projectsService.deleteAsset,
      token,
      action.payload
    )
    yield put(projectsDeleteAssetSuccess(response))
    yield put(projectsStopLoading())
  } catch (error) {
    yield put(projectsDeleteAssetFailure(error))
    yield put(projectsStopLoading())
  }
}

export function* watchProjects() {
  yield all([
    takeEvery(actions.PROJECTS_FETCH_ALL, fetchAllProjects),
    takeEvery(actions.PROJECTS_FETCH_MY, fetchMyProjects),
    takeEvery(actions.PROJECTS_FETCH_DETAIL, fetchProjectsDetail),
    takeEvery(actions.PROJECTS_SEND_APPLICATION, sendApplication),
    takeEvery(actions.PROJECTS_PATCH_VISITED, patchVisited),
    takeEvery(actions.PROJECTS_ADD_ASSET, addAsset),
    takeEvery(actions.PROJECTS_EDIT_ASSET, editAsset),
    takeEvery(actions.PROJECTS_DELETE_ASSET, deleteAsset)
  ])
}
