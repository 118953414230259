import firebase from './firebase'
import { errors } from '../utils'

export default class FirebaseService {
  register = async (data) => {
    try {
      const {
        email,
        password,
        firstName,
        lastName,
        phone,
        sohoHouseMemberNumber
      } = data
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(({ user }) => user)
      return await this.getUserToken().then((token) => {
        return {
          token,
          user: { firstName, lastName, phone, email, sohoHouseMemberNumber }
        }
      })
    } catch (err) {
      throw errors.parse(err)
    }
  }

  emailVerify = async (actionCode) => {
    try {
      return await firebase.auth().applyActionCode(actionCode)
    } catch (err) {
      throw errors.parse(err)
    }
  }

  login = async (email, password) => {
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
      if (!response.user.emailVerified) {
        // TODO: We should implement resending verification email in phase 2
        // await response.user.sendEmailVerification()
        throw { code: 'auth/verify-your-email' }
      }
      return await this.getUserToken()
    } catch (err) {
      throw errors.parse(err)
    }
  }

  logout = async () => {
    try {
      return await firebase.auth().signOut()
    } catch (err) {
      throw errors.parse(err)
    }
  }

  passwordResetEmail = async ({ email }) => {
    try {
      const sendPasswordResetEmail = firebase
        .functions()
        .httpsCallable('webFunctions-sendPasswordResetEmail')

      return await sendPasswordResetEmail({ email })
    } catch (err) {
      throw errors.parse(err)
    }
  }

  passwordReset = async (actionCode, password) => {
    try {
      await firebase.auth().verifyPasswordResetCode(actionCode)
      return await firebase.auth().confirmPasswordReset(actionCode, password)
    } catch (err) {
      throw errors.parse(err)
    }
  }

  getUserToken = async () => {
    try {
      return await firebase.auth().currentUser.getIdToken()
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  changeEmail = async (email) => {
    try {
      const user = firebase.auth().currentUser
      const cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        'qwerty'
      )
      await user.reauthenticateWithCredential(cred)
      return await user.updateEmail(email)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  changePassword = async ({ password, newPassword }) => {
    try {
      const user = firebase.auth().currentUser
      const cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        password
      )
      await user.reauthenticateWithCredential(cred)
      return await user.updatePassword(newPassword)
    } catch (err) {
      throw errors.parse(err)
    }
  }
}
