import { errors } from '../utils'
import environment from '../environment/environment'

export default class UserService {
  _apiBase = environment.apiUrl + '/api/'

  getUser = async (token) => {
    const response = await fetch(`${this._apiBase}user`, {
      headers: {
        'Content-Type': 'application/json',
        token: token
      }
    })
    if (response.ok) {
      return await response.json()
    } else {
      throw await response.json()
    }
  }

  postUser = async (data) => {
    try {
      const { token, user } = data
      const response = await fetch(`${this._apiBase}user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
        body: JSON.stringify({ user })
      })
      return await response.json()
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  putUser = async (token, data) => {
    const { uuid } = data
    const dataCopy = { ...data }
    Object.keys(dataCopy).forEach(
      (key) =>
        (!dataCopy[key] || key === 'uuid' || key === 'role') &&
        delete dataCopy[key]
    )
    const user = { user: { ...dataCopy, rate: Number(dataCopy.rate) } }
    const response = await fetch(`${this._apiBase}users/${uuid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token
      },
      body: JSON.stringify(user)
    })
    if (response.ok) {
      return user
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }
}
