import React from 'react'

import { Email20 } from '@carbon/icons-react'
import SocialLink from '../../shared/SocialLink'

import no_photo from '../../../assets/images/no_photo.png'
import './TeamMember.scss'
import { useSelector } from 'react-redux'

const TeamMember = ({ member, className }) => {
  console.log(member)
  const classList = `member ${className ? className : ''} ${
    member.position ? 'member--f-center' : ''
  }`
  const project = useSelector(({ projects }) => projects.selected)
  return (
    <div className={classList}>
      <div className="member__photo">
        <img src={member.photo || no_photo} alt="Member" />
      </div>
      <div className="member__info">
        <p className="c-label">{member.position || 'Project Manager'}</p>
        <p className="c-text">{`${member.firstName} ${member.lastName}`}</p>
      </div>
      <div className="member__contact">
        {!member.position && <p className="c-label">Contact</p>}
        <SocialLink
          link={`mailto:${member.email}?subject=Lightbox - ${project.name}`}
          icon={<Email20 />}
        />
      </div>
    </div>
  )
}

export default TeamMember
