import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Form, InlineLoading, TextInput } from 'carbon-components-react'

import {
  authInfoClear,
  authRegister,
  authSetRegister
} from '../../../store/auth/actions'
import { setShowModal } from '../../../store/modal/actions'
import { actions } from '../../../constants'
import { capitalize, sha256 } from 'utils/general'
import '../../../validations'

import './Register.scss'

const Register = () => {
  const dispatch = useDispatch()

  const auth = useSelector(({ auth }) => auth)

  const [loading, setLoading] = useState({ status: '', description: '' })

  const onSubmit = (values) => {
    dispatch(authSetRegister())
    dispatch(
      authRegister({
        ...values,
        phone: String(values.phone),
        password: sha256(values.password)
      })
    )
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      sohoHouseMemberNumber: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('This field is required.'),
      lastName: Yup.string()
        .min(2, 'This field should contain at least 2 symbols.')
        .required('This field is required.'),
      email: Yup.string()
        .email('Please enter valid email address.')
        .required('This field is required.'),
      password: Yup.string()
        .min(6, 'This field should contain at least 6 characters.')
        .required('This field is required.'),
      confirmPassword: Yup.string()
        .required('This field is required.')
        .oneOf([Yup.ref('password')], 'Password fields should match.'),
      phone: Yup.string().phone().required('This field is required.'),
      sohoHouseMemberNumber: Yup.number().required('This field is required.')
    }),
    onSubmit
  })

  const { values, handleChange, touched, handleBlur, handleSubmit } = formik

  let timeout = useRef(null)

  useEffect(() => {
    if (formik.submitCount) {
      if (auth.loading) {
        setLoading((prevState) => ({ ...prevState, status: 'active' }))
      } else {
        setLoading((prevState) => ({ ...prevState, status: '' }))
      }
      if (auth.response?.type === actions.AUTH_REGISTER_SUCCESS) {
        setLoading({ status: '', description: '' })
        formik.resetForm()
        dispatch(authInfoClear())
        dispatch(setShowModal('accountVerify'))
      } else if (auth.error) {
        setLoading({ status: 'error', description: auth.error.data })
        timeout.current = setTimeout(() => {
          setLoading({ status: '', description: '' })
          dispatch(authInfoClear())
        }, 3000)
      }
    }
  }, [auth, dispatch, formik.submitCount])

  // eslint-disable-next-line
  useEffect(() => () => clearTimeout(timeout.current), [])

  const handleCapital = (e) => {
    e.preventDefault()
    formik.setFieldValue(e.target.id, capitalize(e.target.value))
  }

  const fieldsList = [
    {
      id: 'firstName',
      className: 'auth__input',
      value: values.firstName,
      invalid: !!formik.errors.firstName && touched.firstName,
      invalidText: formik.errors.firstName,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'First Name',
      placeholder: 'Name',
      touched: String(touched.firstName)
    },
    {
      id: 'lastName',
      className: 'auth__input',
      value: values.lastName,
      invalid: !!formik.errors.lastName && touched.lastName,
      invalidText: formik.errors.lastName,
      onBlur: handleBlur,
      onChange: handleChange,
      onKeyUp: handleCapital,
      labelText: 'Last Name',
      placeholder: 'Name',
      touched: String(touched.lastName)
    },
    {
      id: 'email',
      type: 'email',
      className: 'auth__input',
      value: values.email,
      invalid: !!formik.errors.email && touched.email,
      invalidText: formik.errors.email,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Email',
      placeholder: 'Email',
      touched: String(touched.password)
    },
    {
      id: 'phone',
      className: 'auth__input',
      value: values.phone,
      invalid: !!formik.errors.phone && touched.phone,
      invalidText: formik.errors.phone,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Phone',
      placeholder: 'Number',
      touched: String(touched.phone)
    },
    {
      id: 'password',
      className: 'auth__input',
      value: values.password,
      invalid: !!formik.errors.password && touched.password,
      invalidText: formik.errors.password,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Password',
      placeholder: 'Password',
      touched: String(touched.password)
    },
    {
      id: 'confirmPassword',
      className: 'auth__input',
      value: values.confirmPassword,
      invalid: !!formik.errors.confirmPassword && touched.confirmPassword,
      invalidText: formik.errors.confirmPassword,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'Confirm Password',
      placeholder: 'Password',
      touched: String(touched.confirmPassword)
    },
    {
      id: 'sohoHouseMemberNumber',
      type: 'number',
      className: 'auth__input',
      value: values.sohoHouseMemberNumber,
      invalid:
        !!formik.errors.sohoHouseMemberNumber && touched.sohoHouseMemberNumber,
      invalidText: formik.errors.sohoHouseMemberNumber,
      onBlur: handleBlur,
      onChange: handleChange,
      labelText: 'SoHo House Member Number',
      placeholder: 'Number',
      touched: String(touched.sohoHouseMemberNumber)
    }
  ]

  const fields = fieldsList.map((field, key) => {
    if (field.id === 'password' || field.id === 'confirmPassword') {
      return <TextInput.PasswordInput key={key} {...field} />
    } else {
      return <TextInput key={key} {...field} />
    }
  })

  return (
    <>
      <h3 className="auth__title">Create your Lightbox account.</h3>
      <h3 className="auth__steps">You must be a member of SoHo House.</h3>
      <span className="auth__steps">Step 1 of 2</span>
      <Form className="auth__form" onSubmit={handleSubmit}>
        <div className="l-form-wrap">{fields}</div>
        <div className="l-btn-wrap">
          {loading.status && (
            <InlineLoading
              status={loading.status}
              description={loading.description}
            />
          )}
          <Button type="submit" className="bx--btn--mt-3">
            Create Account
          </Button>
        </div>
      </Form>
    </>
  )
}

export default Register
