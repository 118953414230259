import { errors } from '../utils'
import environment from '../environment/environment'

export default class ProjectsService {
  _apiBase = environment.apiUrl + '/api/'

  getProjects = async (token, type, amount = 0) => {
    const response = await fetch(
      `${this._apiBase}projects?tab=${type}&amount=${amount}`,
      {
        headers: {
          'Content-Type': 'application/json',
          token: token
        }
      }
    )
    if (response.ok) {
      return await response.json()
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }

  getProject = async (token, uuid) => {
    const response = await fetch(`${this._apiBase}projects/${uuid}`, {
      headers: {
        'Content-Type': 'application/json',
        token: token
      }
    })
    if (response.ok) {
      return await response.json()
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }

  sendApplication = async (token, data) => {
    const { uuid } = data
    const application = { application: { ...data.application } }
    const response = await fetch(
      `${this._apiBase}projects/${uuid}/application`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
        body: JSON.stringify(application)
      }
    )
    if (response.ok) {
      return response
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }

  patchVisited = async (token, uuid) => {
    const response = await fetch(`${this._apiBase}projects/${uuid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        token: token
      }
    })
    if (response.ok) {
      return response
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }

  addAsset = async (token, data) => {
    const { projectUuid } = data
    const asset = { asset: { ...data.asset, permissions: 'all_teammates' } }
    const response = await fetch(
      `${this._apiBase}projects/${projectUuid}/asset`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
        body: JSON.stringify(asset)
      }
    )
    if (response.ok) {
      return await response.json()
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }

  editAsset = async (token, data) => {
    const { projectUuid, assetUuid } = data
    const asset = { asset: { ...data.asset, permissions: 'all_teammates' } }
    const response = await fetch(
      `${this._apiBase}projects/${projectUuid}/asset/${assetUuid}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
        body: JSON.stringify(asset)
      }
    )
    if (response.ok) {
      return { asset: { ...data.asset }, uuid: assetUuid }
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }

  deleteAsset = async (token, data) => {
    const { projectUuid, assetUuid } = data
    const response = await fetch(
      `${this._apiBase}projects/${projectUuid}/asset/${assetUuid}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          token: token
        }
      }
    )
    if (response.ok) {
      return assetUuid
    } else {
      const error = await response.json()
      throw errors.parse(error)
    }
  }
}
