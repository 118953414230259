import { actions } from '../../constants'

export const defaultState = {
  isRegister: false,
  loading: false,
  response: null,
  error: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.AUTH_LOGIN_SUCCESS:
    case actions.AUTH_REGISTER_SUCCESS:
    case actions.AUTH_EMAIL_VERIFY_SUCCESS:
    case actions.AUTH_CHANGE_PASSWORD_SUCCESS:
    case actions.AUTH_PASSWORD_RESET_EMAIL_SUCCESS:
    case actions.AUTH_PASSWORD_RESET_SUCCESS:
    case actions.AUTH_LOGOUT_SUCCESS:
      return { ...state, response: { type: action.type, data: action.payload } }
    case actions.AUTH_LOGIN_FAILURE:
    case actions.AUTH_REGISTER_FAILURE:
    case actions.AUTH_EMAIL_VERIFY_FAILURE:
    case actions.AUTH_CHANGE_PASSWORD_FAILURE:
    case actions.AUTH_PASSWORD_RESET_EMAIL_FAILURE:
    case actions.AUTH_PASSWORD_RESET_FAILURE:
    case actions.AUTH_LOGOUT_FAILURE:
      return { ...state, error: { type: action.type, data: action.payload } }
    case actions.AUTH_START_LOADING:
      return { ...state, loading: true }
    case actions.AUTH_STOP_LOADING:
      return { ...state, loading: false }
    case actions.AUTH_INFO_CLEAR:
      return { ...state, error: null, response: null }
    case actions.AUTH_SET_REGISTER:
      return { ...state, isRegister: true }
    case actions.AUTH_CLEAR_REGISTER:
      return { ...state, isRegister: false }
    default:
      return state
  }
}
