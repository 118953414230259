import actions from 'constants/actions'

const initialState = {
  showProfileAfterLogin: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_PROFILE_AFTER_LOGIN:
      return {
        ...state,
        showProfileAfterLogin: action.payload
      }
    default:
      return state
  }
}
