import React from 'react'
import { SkeletonPlaceholder, SkeletonText } from 'carbon-components-react'

const TeamMemberSkeleton = ({ className }) => {
  const classList = `member ${className ? className : ''}`

  return (
    <div className={classList}>
      <div className="member__photo">
        <SkeletonPlaceholder
          style={{ width: '64px', height: '64px', marginRith: '20px' }}
        />
      </div>
      <div className="member__info">
        <SkeletonText paragraph lineCount={2} width={'150px'} />
      </div>
      <div className="member__contact">
        <SkeletonPlaceholder
          style={{ width: '56px', height: '48px', marginLeft: 'auto' }}
        />
      </div>
    </div>
  )
}

export default TeamMemberSkeleton
